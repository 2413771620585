<template>
    <div id="pce">
        <div id="give_device_to_sc_popup" class="text-center" v-if="$root.show_hand_back_device_popup">
            <h2 class="font-weight-bold">
                Thank you
                <br>
                Please hand the device back to your Secure Care crew
            </h2>
            <br><br>
            <div class="btn btn-primary" style="width: 250px;" @click="$root.show_hand_back_device_popup=false">BACK TO PCE</div>
        </div>

        <app-pce-nav v-model="active_pce_tab"></app-pce-nav>

        <div v-if="active_pce_tab==='pce_assignment'">
            <app-pce-assignment v-model="value"></app-pce-assignment>
        </div>
        <div v-if="active_pce_tab==='patient_into_care'">
            <app-pce-patient-into-care v-model="value"></app-pce-patient-into-care>
        </div>

        <div v-if="active_pce_tab==='rsri'"> <!-- rsri stands for Restraints, searches, and  reportable incidents -->
            <app-pce-restraints-searches-and-incidents v-model="value"></app-pce-restraints-searches-and-incidents>
        </div>


        <div v-if="active_pce_tab==='patient_handover'">
            <app-pce-handover v-model="value"></app-pce-handover>
        </div>

        <div v-if="active_pce_tab==='ra'">
            <app-pce-risk_assessment v-model="value"></app-pce-risk_assessment>
        </div>

        <div v-if="active_pce_tab==='sign_off'">
            <app-pce-sign-off v-model="value" :pce_merge_errors="pce_merge_errors"></app-pce-sign-off>
        </div>




    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        pce_merge_errors: {
            type: Object,
        },
    },
    data() {
        return {
            active_pce_tab: 'pce_assignment', // Default pce_assignment
        }
    },
    computed: {
        'patientInitials': function() {
            var patientInitials = '';
            if (this.value.patient_name!==null) {
                this.value.patient_name.split(" ").forEach((name)=>{
                    patientInitials += name[0];
                });
            }
            return patientInitials;
        }
    },
    watch: {
        active_pce_tab: function (newTab, oldTab) {
            Sentry.addBreadcrumb({
                category: "navigation",
                message: "Changed PCE page to " + newTab,
                level: Sentry.Severity.Info,
            });

            // If the journey has been cancelled, the crew can only view the first page
            if (this.value.cancelled&&newTab!=='pce_assignment') {
                this.active_pce_tab = 'pce_assignment';
                Sentry.addBreadcrumb({
                    category: "navigation",
                    message: "System changed PCE page to " + 'pce_assignment',
                    level: Sentry.Severity.Info,
                });
            }

            if (this.value.current_journey_step_id===null) {
                // The journey has not been started yet, so the operator can only view the assignment details page, and the risk assessment page
                if (newTab!=='pce_assignment'&&newTab!=='ra') {
                    this.active_pce_tab = oldTab;
                    Sentry.addBreadcrumb({
                        category: "navigation",
                        message: "System changed PCE page to " + 'pce_assignment',
                        level: Sentry.Severity.Info,
                    });
                }
            }
        }
    },
    methods: {
        save: function () {
            this.$parent.queue_save_current_pce();
        },
    }
}
</script>

<style>
#test_button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 100000000;
}
.section_heading {
    font-size: 14px;
    font-weight: bold;
    color: #5A5A5A;
    margin-bottom: 12px;
}
.pce_card {
    margin: 0 20px 20px 20px;
    background-color: white;
    box-shadow: 0 0 4px #00000069;
    min-height: 500px;
    padding: 20px 0;
}
.pce_card_content {
    padding: 20px 30px;
}
.data_label {
    font-weight: bold;
    color: #959595;
    font-size: 13px;
    margin-bottom: 0;
}
.data {
    font-size: 14px;
    color: #5A5A5A;
}
.input_label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
}
#give_device_to_sc_popup {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 1100;
    background-color: #EEEEEE;
    padding: 30px;

}
</style>
