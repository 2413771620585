<template>
<div>
    <div v-for="(item_value, item_index) in items">
        <div class="input-group mb-2" v-if="editable">
            <input class="form-control" v-model="items[item_index]" @blur="$emit('change')">
            <div class="input-group-append" @click="items.splice(item_index,1); $emit('change')">
                <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 10">
                        <path id="Path_27" data-name="Path 27" d="M15,6.007,13.993,5,10,8.993,6.007,5,5,6.007,8.993,10,5,13.993,6.007,15,10,11.007,13.993,15,15,13.993,11.007,10Z" transform="translate(-5 -5)" fill="#676767"/>
                    </svg>
                </span>
            </div>
        </div>
        <div v-else>
            {{items[item_index]}}
        </div>
    </div>
    <span class="btn-primary btn-sm btn" @click="items.push(''); $emit('change')" v-if="editable">ADD<span v-if="items.length>=1"> ANOTHER</span></span>
    <div v-else-if="items.length===0">None</div>
    <br><br>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        'editable': {
            type: Boolean,
            default: true,
        }
    },
    data: function () {
        return {
            items: []
        }
    },
    watch: {
        items: {
            deep: true,
            handler(value) {
                this.$emit('input', JSON.stringify(this.items));
            }
        },
        value: function () {
            if (this.value!==null) {
                this.items = JSON.parse(this.value)
            }
        }
    },
    methods: {
        set_items_ar: function() {
            if (this.value!==null) {
                this.items = JSON.parse(this.value)
            }
        }
    },
    beforeUpdate: function (){
        this.set_items_ar()
    },
    mounted: function() {
        this.set_items_ar()
    },

name: "AppFreeTextListComponent.vue"
}
</script>

<style scoped>

</style>
