<template>
    <div>
        <div class="popup_container" v-if="editing_note_item!==null">
            <div class="close_popup"></div>
            <div class="popup_content_container">
                <div class="text-center pt-4">
                    <div class="popup_title">Edit Note</div>
                </div>
                <div class="hl my-4"></div>
                <div class="px-4 pb-4">
                    <textarea class="form-control" rows="4" v-model="value.journey_notes[editing_note_item]['content']"></textarea>
                    <br><br>
                    <div class="btn btn-block btn-primary" @click="editing_note_item=null; $root.queue_save_current_pce()">Save</div>
                    <div class="btn btn-block btn-danger" @click="value.journey_notes.splice(editing_note_item, 1); editing_note_item=null; $root.queue_save_current_pce()">Delete</div>
                </div>
            </div>
        </div>
        <div class="popup_container" v-if="confirm_step_change">
            <div class="close_popup"></div>
            <div class="popup_content_container">
                <div class="text-center pt-4">
                    <div class="popup_title">Change Job Status</div>
                </div>
                <div class="hl my-4"></div>

                <!-- Arrived at destination odometer/mileage reading -->
                <div v-if="confirm_step_change_details['step_type']==='arrived_at_destination' || confirm_step_change_details['step_type']==='arrived_at_collection'">
                    <!--Odometer reading components-->
                    <div class="px-4 pb-4">
                        <input class="form-control" v-model="odometer" type="number" placeholder="Enter vehicle mileage" id="odometer" name="odometer" required>
                    </div>
                </div>


                <div class="px-4 pb-4" v-if="confirm_step_change_details['step_type']==='appointment_start_out_of_care'">
                    <center><h5>Is the patient is going to still be in SecureCare’s care?</h5></center>
                    <br>
                    <div class="btn btn-block btn-primary" @click="should_take_obs_apt=true; confirm_step_change=false; start_step(null, confirm_step_change_details['step_type'], confirm_step_change_details['journey_step_index'], true)">YES</div>
                    <div class="btn btn-block btn-primary" @click="should_take_obs_apt=false; confirm_step_change=false; start_step(null, confirm_step_change_details['step_type'], confirm_step_change_details['journey_step_index'], true)">NO</div>
                    <div class="btn btn-block btn-outline-primary" @click="confirm_step_change=false">CANCEL</div>
                </div>

                <div class="px-4 pb-4" v-else>
                    <div v-if="confirm_step_change_details['step_type']==='arrived_at_destination' || confirm_step_change_details['step_type']==='arrived_at_collection'" class="btn btn-block btn-primary" @click="confirm_step_change=false; set_reading(confirm_step_change_details['journey_step_index']); start_step(null, confirm_step_change_details['step_type'], confirm_step_change_details['journey_step_index'], true)">CHANGE JOB STATUS</div>
                    <div v-else class="btn btn-block btn-primary" @click="confirm_step_change=false; start_step(null, confirm_step_change_details['step_type'], confirm_step_change_details['journey_step_index'], true)">CHANGE JOB STATUS</div>
                    <div class="btn btn-block btn-outline-primary" @click="confirm_step_change=false">CANCEL</div>
                </div>
            </div>
        </div>
        <div id="journey_step_bar" @click="toggle_bar()" :class="{'jsb_danger':value.started_at===null&&value.crew_accepted_transfer_at!==null}">
            <div class="pl-3">
                <div id="journey_step_bar_title">{{currently_doing_based_on_journey_step_label}}</div>
                <div id="journey_step_bar_crew">Crew: {{current_crew_string}}</div>
            </div>
            <div class="float-right pr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.715" height="12" viewBox="0 0 13.715 12">
                    <path id="play" d="M11.368,5.782,1.939.208A1.279,1.279,0,0,0,0,1.314V12.46a1.285,1.285,0,0,0,1.939,1.106l9.429-5.572a1.284,1.284,0,0,0,0-2.213Z" transform="translate(13.747) rotate(90)" fill="#4d4d4d"/>
                </svg>
            </div>

        </div>
        <div id="journey_observations_bar" v-if="$root.taking_pt_obs">
            <div class="row py-2">
                <div class="col-10">
                    <div class="px-3 pb-2 border-bottom" style="border-bottom-color: #7ED1E5;">
                        <div class="observations_bar_title">Patient presentation</div><br>
                        <div class="observations_bar_value">{{$root.presentation_options[current_presentation]}}</div>
                        <div class="observations_bar_value_details">for {{time_since_presentation_obs_changed}}</div>
                    </div>
                    <div class="px-3 pt-2">
                        <div class="observations_bar_title">Level of restraint</div><br>
                        <div class="observations_bar_value">{{$root.restraint_level_options[current_restraint]}}</div>
                        <div class="observations_bar_value_details">for {{time_since_restraint_obs_changed}}</div>
                    </div>
                </div>
                <div class="col-2 d-flex align-items-center" @click="pt_obs_expanded=true">
                    <div class="btn btn-outline-light btn-sm">CHANGE</div>
                </div>
            </div>
        </div>

        <div id="journey_observations_popped_out_overlay" v-if="pt_obs_expanded">
            <div id="journey_observations_popped_out_container">
                <div class="journey_bar_popped_out_section">
                    <div class="font-weight-bold">Patient presentation</div>
                    <select class="form-control" v-model="current_presentation" @change="current_presentation_changed()">
                        <option v-for="(presentation_label, presentation_level_value) in $root.presentation_options" :value="presentation_level_value">
                            {{ presentation_label }}</option>
                    </select>
                    <small class="text-muted">for {{time_since_presentation_obs_changed}}</small>
                    <br><br>
                    <div class="font-weight-bold">Level of restraint</div>
                    <select class="form-control" v-model="current_restraint" @change="current_restraint_changed()">
                        <option v-for="(restraint_label, restraint_level_value) in $root.restraint_level_options" :value="restraint_level_value">
                            {{ restraint_label }}</option>
                    </select>
                    <small class="text-muted">for {{time_since_restraint_obs_changed}}</small>
                    <br><br>
                    <div class="font-weight-bold">Patient transfer notes</div>

                    <!-- todo: make this note input into a component  -->
                    <div v-for="(note_item, note_item_index) in value.journey_notes" class="mb-1">
                        <div class="border rounded p-2 d-flex note_item_container" @click="editing_note_item = note_item_index">
                            <div class="note_item_time">{{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(note_item['date_added']))}}</div>
                            <div class="text-truncate note_item_content">{{note_item['content']}}</div>
                        </div>
                    </div>
                    <div class="btn btn-sm btn-primary" id="add_note_btn" @click="add_note()">Add note</div>

                    <br><br>
                    <div class="btn btn-outline-primary btn-block" @click="pt_obs_expanded=false" >CLOSE</div>

                </div>
            </div>


        </div>
        <div id="journey_step_popped_out_overlay" v-if="expanded">
            <div id="journey_step_popped_out_container">
                <div class="journey_bar_popped_out_section">
                    <div v-for="(journey_step, journey_step_index) in value.journey_steps" :key="journey_step.id">
                        <div v-if="journey_step.type === 'collection'">
                            <div class="journey_step_popped_out_step" :class="{'done':value.crew_accepted_transfer_at!==null}">
                                Crew acknowledge receipt of transfer. Not Started
                                <span class="journey_step_popped_out_step_time_box" v-if="value.crew_accepted_transfer_at!==null">
                                    {{value.crew_accepted_transfer_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(value.crew_accepted_transfer_at))}}
                                </span>
                            </div>
                            <div class="journey_step_popped_out_step" :class="{'done':value.started_at!==null}">
                                Crew start transfer
                                <span class="journey_step_popped_out_step_time_box" v-if='value.started_at!==null'>
                                    {{value.started_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(value.started_at))}}
                                </span>
                            </div>


                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.started_at!==null,'clickable':is_step_clickable('arrived_at_collection', journey_step_index)}"
                                 @click="start_step($event, 'arrived_at_collection', journey_step_index)">
                                Arrived at collection location
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.started_at!==null'>
                                    {{journey_step.started_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.started_at))}}
                                </span>
                                <div v-if="journey_step.quote_id">
                                    <hr>
                                    Odometer Reading
                                    <span class="journey_step_popped_out_step_time_box">
                                        {{ journey_step.quote_id }} Miles
                                    </span>
                                </div>
                            </div>
                            <div class="journey_step_popped_out_step not_user_inputted">Collection - Doctor signs patient into our care</div>
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.ended_at!==null,'clickable':is_step_clickable('departed_from_collection', journey_step_index)}"
                                 @click="start_step($event, 'departed_from_collection', journey_step_index)">
                                Departed from collection location
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.ended_at!==null'>
                                    {{journey_step.ended_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.ended_at))}}
                                </span>
                            </div>
                            <br>
                        </div>
                        <div v-if="journey_step.type === 'other_step'">
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.started_at!==null,'clickable':is_step_clickable('appointment_start', journey_step_index)}"
                                 @click="start_step($event, 'appointment_start', journey_step_index)">
                                Arrived at {{journey_step.description}}
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.started_at!==null'>
                                    {{journey_step.started_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.started_at))}}
                                </span>
                                <div v-if="journey_step.quote_id">
                                    <hr>
                                    Odometer Reading
                                    <span class="journey_step_popped_out_step_time_box">
                                        {{ journey_step.quote_id }} Miles
                                    </span>
                                </div>
                            </div>
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.started_out_of_care_at!==null,'clickable':is_step_clickable('appointment_start_out_of_care', journey_step_index)}"
                                 @click="start_step($event, 'appointment_start_out_of_care', journey_step_index)">
                                Patient handed over at start of {{journey_step.description}}
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.started_out_of_care_at!==null'>
                                    {{journey_step.started_out_of_care_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.started_out_of_care_at))}}
                                </span>
                            </div>
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.ended_out_of_care_at!==null,'clickable':is_step_clickable('appointment_end_out_of_care', journey_step_index)}"
                                 @click="start_step($event, 'appointment_end_out_of_care', journey_step_index)">
                                Patient back into care at end of {{journey_step.description}}
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.ended_out_of_care_at!==null'>
                                    {{journey_step.ended_out_of_care_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.ended_out_of_care_at))}}
                                </span>
                            </div>
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.ended_at!==null,'clickable':is_step_clickable('appointment_end', journey_step_index)}"
                                 @click="start_step($event, 'appointment_end', journey_step_index)">
                                Departed from {{journey_step.description}}
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.ended_at!==null'>
                                     {{journey_step.ended_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.ended_at))}}
                                </span>
                            </div>
                            <br>
                        </div>

                        <div v-if="journey_step.type === 'destination'">
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.started_at!==null,'clickable':is_step_clickable('arrived_at_destination', journey_step_index)}"
                                 @click="start_step($event, 'arrived_at_destination', journey_step_index)">
                                Arrived at destination location
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.started_at!==null'>
                                    {{journey_step.started_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.started_at))}}
                                </span>
                                <div v-if="journey_step.quote_id">
                                    <hr>
                                    Odometer Reading
                                    <span class="journey_step_popped_out_step_time_box">
                                        {{ journey_step.quote_id }} Miles
                                    </span>
                                </div>
                            </div>
                            <div class="journey_step_popped_out_step not_user_inputted">Destination - Doctor signs patient out of our care</div>
                            <div class="journey_step_popped_out_step"
                                 :class="{'done':journey_step.ended_at!==null,'clickable':is_step_clickable('crew_clear', journey_step_index)}"
                                 @click="start_step($event, 'crew_clear', journey_step_index)">
                                Crew clear
                                <span class="journey_step_popped_out_step_time_box" v-if='journey_step.ended_at!==null'>
                                   {{journey_step.ended_at.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(journey_step.ended_at))}}
                                </span>
                            </div>
                            <br>
                        </div>
                    </div>
                    <br>
                    <div class="journey_step_popped_out_step"
                         :class="{'clickable':is_step_clickable('end_pce', null)}"
                         @click="start_step($event, 'end_pce', null)">
                        End PCE
                    </div>

                </div>

                <!--                <div class="hl"></div>-->
<!--                <div class="journey_bar_popped_out_section">-->
<!--                    <div class="font-weight-bold">Other stuff</div>-->
<!--                </div>-->

            </div>

        </div>

        <div id="pt_obs_spacer" v-if="$root.taking_pt_obs"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        current_crew_string: function () {
            let crew_to_return = '';
            if (this.$root.currentCVA.crew_members) {
                crew_to_return = Object.values(this.$root.currentCVA.crew_members).join(', ')
            }
            return crew_to_return;
        },
        currently_doing_based_on_journey_step_label: function () {
            if (this.value.started_at===null) {
              if (this.value.crew_accepted_transfer_at===null) {
                  return 'Standby'
              } else {
                  return 'Standby - Transfer not started'
              }
            } else {
                var next_journey_step = this.value.journey_steps[this.find_journey_step_by_id(this.value.current_journey_step_id, true)+1];
                var next_step_description = 'Destination'
                if (next_journey_step!==undefined&&next_journey_step.type==="other_step") {
                    next_step_description = next_journey_step.description
                }
                if (this.value.current_journey_step_id===null) {
                    return 'Driving to Collection'
                } else {
                    var current_journey_step = this.find_journey_step_by_id(this.value.current_journey_step_id)
                    console.log('current_journey_step', current_journey_step)
                    if (current_journey_step.type==="collection") {
                      if (current_journey_step.ended_at!==null) {
                          return 'Driving to '+next_step_description
                      } else if (this.value.into_care_dr_signature!==null) {
                          return 'Patient in care. Returning to Vehicle';
                      } else {
                          return 'Collecting Patient'
                      }
                    } else if (current_journey_step.type==="other_step") {
                      if (current_journey_step.ended_at!==null) {
                          return 'Driving to '+next_step_description
                      } else if (current_journey_step.ended_out_of_care_at!==null) {
                          return 'Returning from '+current_journey_step.description;
                      } else if (current_journey_step.started_out_of_care_at!==null) {
                          return 'Waiting at '+current_journey_step.description;
                      } else {
                          return 'Delivering patient to '+current_journey_step.description;
                      }
                    } else if (current_journey_step.type==="destination") {
                      if (current_journey_step.ended_at!==null) {
                          return 'Returning to Vehicle / Standby or Returning to Base (depending on drop off signature)'
                      } else {
                          return 'Dropping off Patient'
                      }
                    }
                }
                return 'Unknown'
            }
        },
        time_since_presentation_obs_changed: function () {
            return this.secondsToHms((this.$root.currentEpoch - this.started_current_presentation_at)/1000);
        },
        time_since_restraint_obs_changed: function () {
            return this.secondsToHms((this.$root.currentEpoch - this.started_current_restraint_at)/1000);
        },
    },
    methods: {
        secondsToHms: function(d) { // Todo, make this code look nice
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay;
        },
        add_note: function () {
            this.editing_note_item = this.value.journey_notes.push({
                'content': '',
                'date_added': this.$root.date_to_time_string(new Date()),
            })-1;
        },
        find_journey_step_by_id: function (journey_step_id, return_index = false) {
            var journey_step_to_return = []
            this.value.journey_steps.forEach((journey_step, journey_step_index) => {
                if (journey_step.id === journey_step_id) {
                    if (return_index) {
                        journey_step_to_return = journey_step_index;
                    } else {
                        journey_step_to_return = journey_step;
                    }
                }
            })
            return journey_step_to_return;
        },
        is_step_clickable: function (step_type, journey_step_index) {
            let last_journey_step = this.value.journey_steps[journey_step_index-1];
            switch (step_type) {
                case 'arrived_at_collection':
                    return this.value.started_at!==null && this.value.journey_steps[journey_step_index].started_at === null
                case 'departed_from_collection':
                    return  this.value.into_care_dr_signature!==null && this.value.journey_steps[journey_step_index].started_at!==null && this.value.journey_steps[journey_step_index].ended_at === null
                case 'appointment_start':
                    return last_journey_step.ended_at!==null && this.value.journey_steps[journey_step_index].started_at === null
                case 'appointment_start_out_of_care':
                    return this.value.journey_steps[journey_step_index].started_at!==null && this.value.journey_steps[journey_step_index].started_out_of_care_at === null
                case 'appointment_end_out_of_care':
                    return this.value.journey_steps[journey_step_index].started_out_of_care_at!==null && this.value.journey_steps[journey_step_index].ended_out_of_care_at === null
                case 'appointment_end':
                    return this.value.journey_steps[journey_step_index].ended_out_of_care_at!==null && this.value.journey_steps[journey_step_index].ended_at === null
                case 'arrived_at_destination':
                    return last_journey_step.ended_at!==null && this.value.journey_steps[journey_step_index].started_at === null
                case 'crew_clear':
                    return this.value.journey_steps[journey_step_index].started_at!==null && this.value.journey_steps[journey_step_index].ended_at === null
                case 'end_pce':
                    return this.value.journey_steps[this.value.journey_steps.length-1].ended_at !== null //True if the last journey step has been completed

            }
        },
        start_step: function (e, step_type, journey_step_index, step_change_confirmed = false) {
            if (this.is_step_clickable(step_type, journey_step_index)) {
                if (step_change_confirmed) {
                    if (this.$root.taking_pt_obs) {
                        this.$root.end_current_restraint_timer();
                        this.$root.end_current_presentation_timer();
                        this.$root.taking_pt_obs = false;
                    }
                    switch (step_type) {
                        case 'arrived_at_collection':
                            this.value.current_journey_step_id = this.value.journey_steps[journey_step_index].id;
                            this.value.journey_steps[journey_step_index].started_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'departed_from_collection':
                            this.init_pt_obs();
                            this.value.journey_steps[journey_step_index].ended_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'appointment_start':
                            this.init_pt_obs();
                            this.value.current_journey_step_id = this.value.journey_steps[journey_step_index].id;
                            this.value.journey_steps[journey_step_index].started_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'appointment_start_out_of_care':
                            if (this.should_take_obs_apt) {
                                this.init_pt_obs();
                            }
                            this.value.journey_steps[journey_step_index].started_out_of_care_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'appointment_end_out_of_care':
                            this.init_pt_obs();
                            this.value.journey_steps[journey_step_index].ended_out_of_care_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'appointment_end':
                            this.init_pt_obs();
                            this.value.journey_steps[journey_step_index].ended_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'arrived_at_destination':
                            this.init_pt_obs();
                            this.value.current_journey_step_id = this.value.journey_steps[journey_step_index].id;
                            this.value.journey_steps[journey_step_index].started_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'crew_clear':
                            this.value.journey_steps[journey_step_index].ended_at = this.$root.date_to_time_string(new Date());
                            break;
                        case 'end_pce':
                            this.$root.$refs.pce_component.active_pce_tab='sign_off';
                            this.expanded = false;
                            break;
                    }
                } else {
                    this.should_take_obs_apt = null;
                    this.confirm_step_change = true;
                    this.confirm_step_change_details = {
                        'take_obs': null,
                        'step_type': step_type,
                        'journey_step_index': journey_step_index,
                        // 'journey_step_button_text': e.target.innerText,
                    };
                }
            }
            this.$root.queue_save_current_pce()
        },
        toggle_bar: function () {
            if (this.value.started_at!==null && this.value.crew_accepted_transfer_at!==null && !this.value.cancelled) {
                this.expanded = !this.expanded;
            }
        },
        start_presentation_timer: function () {
            this.started_current_presentation_at = (new Date()).getTime()
            this.value.presentation_history.push({
                'value': this.current_presentation,
                'started_at': this.$root.date_to_time_string(new Date()),
                'ended_at': null,
            })
        },
        start_restraint_timer: function () {
            this.started_current_restraint_at = (new Date()).getTime()
            this.value.restraint_history.push({
                'value': this.current_restraint,
                'started_at': this.$root.date_to_time_string(new Date()),
                'ended_at': null,
            })
        },
        current_presentation_changed: function () {
            this.$root.end_current_presentation_timer();
            this.start_presentation_timer();
            this.$root.queue_save_current_pce()
        },
        current_restraint_changed: function () {
            this.$root.end_current_restraint_timer();
            this.start_restraint_timer();
            this.$root.queue_save_current_pce()
        },
        init_pt_obs: function () {
            this.$root.taking_pt_obs = true
            // If there is no pt obs history yet, set the initial values the initial obs recorded by the DR at collection
            // If there is also no initial obs from the DR, we will set both current_presentation and current_restraint to 1 (The lowest value)
            if (this.value.presentation_history.length>=1) {
                this.current_presentation = this.value.presentation_history[this.value.presentation_history.length - 1].value
            } else {
                if (this.current_presentation===null) {
                    if (this.value.initial_presentation===null || this.value.initial_presentation===0) {
                        this.current_presentation = "1"
                    } else {
                        this.current_presentation = this.value.initial_presentation
                    }
                }
            }
            if (this.value.restraint_history.length>=1) {
                this.current_restraint = this.value.restraint_history[this.value.restraint_history.length - 1].value
            } else {
                if (this.current_restraint===null) {
                    if (this.value.initial_restraint===null || this.value.initial_restraint===0) {
                        this.current_restraint = "1"
                    } else {
                        this.current_restraint = this.value.initial_restraint
                    }
                }
            }

            // If there aren't any active pt obs history items, let's start a history item...
            if (!this.value.presentation_history.length>=1 || this.value.presentation_history[this.value.presentation_history.length - 1].ended_at!==null) {
                this.start_presentation_timer()
            } else {
                this.started_current_presentation_at = this.$root.time_string_to_date(this.value.presentation_history[this.value.presentation_history.length - 1].started_at).getTime();
            }
            if (!this.value.restraint_history.length>=1 || this.value.restraint_history[this.value.restraint_history.length - 1].ended_at!==null) {
                this.start_restraint_timer()
            } else {
                this.started_current_restraint_at = this.$root.time_string_to_date(this.value.restraint_history[this.value.restraint_history.length - 1].started_at).getTime();
            }
            this.$root.queue_save_current_pce()
        },
        set_reading: function (journey_step_index) {
            console.log(this.value.journey_steps[journey_step_index].id)
            console.log(journey_step_index)
            //Send odometer reading to db
            axios({
                method: 'post',
                url: this.$root.endpoint+'/api/pce/update-odometer',
                data: {
                    pceID: this.value.id,
                    pceStepID: this.value.journey_steps[journey_step_index].id,
                    columnName: 'quote_id',
                    reading: this.odometer,
                }
            }).then(response => {
                // Saved Odometer Reading returned
                this.value.journey_steps[journey_step_index].quote_id = response.data.quote_id
                // clear the fields.
                this.pceStep = "";
                this.odometer = ""
            });
        },
    },
    mounted: function () {
        // Let's see if we were doing pt obs...
        if (this.value.restraint_history.length>=1 && this.value.restraint_history[this.value.restraint_history.length - 1].ended_at===null) {
            // We only need to check if there is a non complete restraint_history history item,
            //      as restraint_history and presentation_history should both have a non complete history item if the operator was still recording patient obs
            this.init_pt_obs()
        }

    },
    data() {
        return {
            should_take_obs_apt: null,
            confirm_step_change: false,
            confirm_step_change_details: {},
            pt_obs_expanded: false,
            started_current_presentation_at: null,
            started_current_restraint_at: null,
            current_presentation: null,
            current_restraint: null,
            editing_note_item: null,
            'expanded': false,
            odometer: null,
            pceStep: ''
        }
    }
}
</script>

<style scoped>
#pt_obs_spacer {
    height: 140px;
}
.note_item_container {
    cursor: pointer;
    user-select: none;
}
.note_item_time {
    width: 80px;
    color: #828282;
}
.note_item_content {
    width: calc(100% - 80px);
}
#journey_observations_bar {
    position: fixed;
    top: 260px;
    width: 100%;
    height: 140px;
    background-color: #65C7DE;
    z-index: 1000;
    user-select: none;
}
.observations_bar_title {
    display: inline-block;
    font-size: 16px;
    color: #DAF3F9;
}
.observations_bar_value {
    font-size: 17px;
    color: white;
    display: inline-block;
    line-height: 10px;
}
.observations_bar_value_details {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    color: #DAF3F9;
}
#journey_step_bar {
    position: fixed;
    top: 180px;
    height: 80px;
    width: 100%;
    z-index: 1000;
    background-color: #FFC34A;
    color: #4D4D4D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}
#journey_step_bar.jsb_danger {
    background-color: #FF4A4A;
    color: white;
}
#journey_step_bar_crew {
    font-size: 15px;
    line-height: 15px;
}
#journey_step_bar_title {
    font-weight: bold;
    font-size: 25px;
    line-height: 20px;
    margin-bottom: 5px;
}
#journey_observations_popped_out_overlay, #journey_step_popped_out_overlay {
    position: fixed;
    top: 260px;
    left: 0;
    width: 100%;
    height: calc(100% - 180px);
    max-height: calc(100% - 260px);
    background-color: rgb(0 0 0 / 60%);
    z-index: 1000;
    overflow: auto;
}
#journey_observations_popped_out_container, #journey_step_popped_out_container {
    width: 100%;
    min-height: 200px;
    background-color: white;
}
.journey_bar_popped_out_section {
    padding: 20px;
}
.journey_step_popped_out_step {
    padding: 10px 20px;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    margin: 10px 0;
    border: 2px solid #707070;
    color: #707070;
}
.journey_step_popped_out_step.clickable {
    background-color: #4A9EFF;
    color: white;
    border-style: none;
}
.journey_step_popped_out_step.done {
    background-color: #67D2A4;
    color: white;
    border-style: none;
}
.journey_step_popped_out_step.not_user_inputted {
    background-color: #E5E5E5;
    color: #707070;

}
.journey_step_popped_out_step_time_box {
    float: right;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
}
.journey_step_popped_out_step_time_edit_icon {
    margin-left: 10px;
}
</style>
