<template>
<div>
    <div class="pce_card">




        <div class="pce_card_content">
            <div class="section_heading pb-4">Physical Medical History</div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have Diabetes?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_diabetes.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_diabetes.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_diabetes.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have a heart condition?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_a_heart_condition.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_a_heart_condition.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_a_heart_condition.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have respiratory conditions?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_respiratory_conditions.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_respiratory_conditions.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_respiratory_conditions.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any blood borne viruses?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_any_blood_borne_viruses.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_any_blood_borne_viruses.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_any_blood_borne_viruses.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have a DNAR in place?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_a_dnar_in_place.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_a_dnar_in_place.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_a_dnar_in_place.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any physical disabilities, such as mobility issues?</div>
                    <div v-if="value.risk_assessment.any_mobility_issues.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.any_mobility_issues.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.any_mobility_issues.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any other medical needs we need to be aware of (such as catheter, stoma, or epilepsy)?</div>
                    <div v-if="value.risk_assessment.other_medical_needs.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.other_medical_needs.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.other_medical_needs.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have fragile skin?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_fragile_skin.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_fragile_skin.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_fragile_skin.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Will the patient have received medication prior to the journey that may make them drowsy?</div>
                    <div v-if="value.risk_assessment.will_the_patient_receive_drowsy_meds.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.will_the_patient_receive_drowsy_meds.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.will_the_patient_receive_drowsy_meds.comments}}</div>
                </div>
            </div>
        </div>

        <div class="hl"></div>

        <div class="pce_card_content">
            <div class="section_heading pb-4">Psychological History</div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have a diagnosis of a mental health condition?</div>
                    <div v-if="value.risk_assessment.diagnosis_of_a_mental_health_condition.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.diagnosis_of_a_mental_health_condition.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.diagnosis_of_a_mental_health_condition.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any learning disabilities?</div>
                    <div v-if="value.risk_assessment.learning_disabilities.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.learning_disabilities.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.learning_disabilities.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any other disabilities that we need to be aware of?</div>
                    <div v-if="value.risk_assessment.other_disabilities.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.other_disabilities.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.other_disabilities.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have a care plan? If Yes can we have a copy so that we can facilitate their needs?</div>
                    <div v-if="value.risk_assessment.care_plan.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.care_plan.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.care_plan.comments}}</div>
                </div>
            </div>

        </div>

        <div class="hl"></div>

        <div class="pce_card_content">
            <div class="section_heading pb-4">Mental Capacity</div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have capacity?</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_capacity.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.does_the_patient_have_capacity.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.does_the_patient_have_capacity.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have DoL's in place or any other court order?</div>
                    <div v-if="value.risk_assessment.patient_has_other_court_order.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.patient_has_other_court_order.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.patient_has_other_court_order.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Is the patient subject to a safeguarding plan or currently involved in any safeguarding proceedings?</div>
                    <div v-if="value.risk_assessment.subject_safeguarding_plan.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.subject_safeguarding_plan.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.subject_safeguarding_plan.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Does the patient have any known triggers and de-escalation techniques?</div>
                    <div v-if="value.risk_assessment.any_known_triggers.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.any_known_triggers.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.any_known_triggers.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Is the patient aware of the transfer?</div>
                    <div v-if="value.risk_assessment.is_patient_aware_of_transfer.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.is_patient_aware_of_transfer.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.is_patient_aware_of_transfer.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Are there any other significant psychological conditions or events that we need to be aware of?</div>
                    <div v-if="value.risk_assessment.other_psychological_conditions.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.other_psychological_conditions.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.other_psychological_conditions.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-9">
                    <div class="text-muted">What is the section status of the patient?</div>
                    <div v-if="value.risk_assessment.section_status.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.section_status.comments}}</div>
                </div>
            </div>

        </div>

        <div v-if="$root.patient_age_on_collection_date<18">
            <div class="hl"></div>

            <div class="pce_card_content">
                <div class="section_heading pb-4">Safeguarding Questions</div>


                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">Has the referrer sought assistance from CAMHS?</div>
                        <div v-if="value.risk_assessment.referrer_sought_assistance_from_camhs.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.referrer_sought_assistance_from_camhs.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.referrer_sought_assistance_from_camhs.comments}}</div>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">Is the Child a looked after child, within the meaning of the Childrens Act 1989?</div>
                        <div v-if="value.risk_assessment.looked_after_child.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.looked_after_child.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.looked_after_child.comments}}</div>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">Has the MASH been notified of the admission/Conveyance?</div>
                        <div v-if="value.risk_assessment.mash_been_notified.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.mash_been_notified.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.mash_been_notified.comments}}</div>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">Name of local authority contacted</div>
                        <div v-if="value.risk_assessment.local_authority_contacted.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.local_authority_contacted.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.local_authority_contacted.comments}}</div>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">What was the name of local authority member spoken to?</div>
                        <div v-if="value.risk_assessment.local_authority_member_name.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.local_authority_member_name.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.local_authority_member_name.comments}}</div>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-6">
                        <div class="text-muted">Is there a safeguarding care plan. Can we see it?</div>
                        <div v-if="value.risk_assessment.safeguarding_care_plan.value===0">No</div>
                        <div v-else>Yes</div>
                    </div>
                    <div class="col-3">
                        <div class="text-muted">Comments</div>
                        <div v-if="value.risk_assessment.safeguarding_care_plan.comments===null">None</div>
                        <div v-else>{{value.risk_assessment.safeguarding_care_plan.comments}}</div>
                    </div>
                </div>


            </div>
        </div>


        <div class="hl"></div>

        <div class="pce_card_content">
            <div class="section_heading pb-4">Risk Score</div>


            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of violence?</div>
                    <div v-if="value.risk_assessment.the_risk_of_violence.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.the_risk_of_violence.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.the_risk_of_violence.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.the_risk_of_violence.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of self-harm?</div>
                    <div v-if="value.risk_assessment.the_risk_of_self_harm.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.the_risk_of_self_harm.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.the_risk_of_self_harm.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.the_risk_of_self_harm.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of suicide?</div>
                    <div v-if="value.risk_assessment.the_risk_of_suicide.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.the_risk_of_suicide.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.the_risk_of_suicide.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.the_risk_of_suicide.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of weapon use?</div>
                    <div v-if="value.risk_assessment.the_risk_of_weapon_use.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.the_risk_of_weapon_use.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.the_risk_of_weapon_use.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.the_risk_of_weapon_use.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of absconding?</div>
                    <div v-if="value.risk_assessment.the_risk_of_absconding.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.the_risk_of_absconding.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.the_risk_of_absconding.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.the_risk_of_absconding.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">The risk of sexually inappropriate behaviour?</div>
                    <div v-if="value.risk_assessment.inappropriate_behaviour_risk.value===0">Low</div>
                    <div v-else-if="value.risk_assessment.inappropriate_behaviour_risk.value===1">Moderate</div>
                    <div v-else>High</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.inappropriate_behaviour_risk.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.inappropriate_behaviour_risk.comments}}</div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-6">
                    <div class="text-muted">Any implications for the mix on crew gender?</div>
                    <div v-if="value.risk_assessment.crew_gender_implications.value===0">No</div>
                    <div v-else>Yes</div>
                </div>
                <div class="col-3">
                    <div class="text-muted">Comments</div>
                    <div v-if="value.risk_assessment.crew_gender_implications.comments===null">None</div>
                    <div v-else>{{value.risk_assessment.crew_gender_implications.comments}}</div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },}
</script>

<style scoped>

</style>
