<template>
    <div>
        <div class="pce_card"  v-if="$root.last_pce_save_successful">
            <div class="pce_card_content">
                <div v-if="Object.keys(format_pce_merge_errors).length>=1">
                    <div class="sign_off_confirmation" v-for="(merge_issue_details, attribute_with_merge_issue) in format_pce_merge_errors" :key="attribute_with_merge_issue">

                        {{ setMergeChoice(attribute_with_merge_issue,merge_issue_details['proposed_new_value']) }}

                    </div>
                    {{ save_merge_issues() }}
                </div>
                <div v-else>
                    <br><br>
                    <center>
                        <div class="btn btn-primary" :class="{disabled: !is_sign_off_confirmation_form_valid}" style="width: 200px;" @click="sign_off()">SIGN OFF PCE</div>
                    </center>
                </div>
            </div>
        </div>
        <div v-else>
            <center>
                <div class="btn btn-primary" :class="{disabled: !is_sign_off_confirmation_form_valid}" style="width: 200px;" @click="sign_off()">SIGN OFF PCE</div>
            </center>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        pce_merge_errors: {
            type: Object,
            required: true,
        },
    },
    methods: {
        sign_off: function () {
            this.$root.show_fullscreen_spinner = true;
            this.value.completed = 1;
            this.$root.sign_off_after_save = true;
            this.$root.queue_save_current_pce();
        },
        reload_page: function () {
            // These 2 lines will save the PCE (and as a result pce_merge_errors will contain any merge issues)
            // and display a loading screen until it's been saved
            this.$root.show_fullscreen_spinner = true;
            this.$root.queue_save_current_pce();
        },
        setMergeChoice: function (attribute_with_merge_issue, value_to_save) {
            this.$set(this.sign_off_confirmation_choices, attribute_with_merge_issue, value_to_save)
        },
        save_merge_issues: function () {
            if (this.is_sign_off_confirmation_form_valid) {
                Object.entries(this.sign_off_confirmation_choices).forEach(attribute_with_merge_error => {
                    // We will set the 'before' value, to the value that is currently set in the DB
                    console.log('test',attribute_with_merge_error)
                    this.$root.pce_not_synced_changes['pce-'+this.value.id][attribute_with_merge_error[0]]['before'] = this.pce_merge_errors[attribute_with_merge_error[0]]['current_value_in_db']

                    // Set the 'after' value to the one chosen by the operator
                    var temp_new_value = null
                    if (attribute_with_merge_error[1]==='proposed_value') {
                        // The value the operator originally inputted into the date input
                        temp_new_value = this.value[attribute_with_merge_error[0]];
                    } else {
                        // The value that is currently set in the db
                        temp_new_value = this.pce_merge_errors[attribute_with_merge_error[0]]['current_value_in_db']
                    }
                    if (typeof temp_new_value === 'string') {
                        this.$root.pce_not_synced_changes['pce-'+this.value.id][attribute_with_merge_error[0]]['after'] = temp_new_value
                    } else {
                        this.$root.pce_not_synced_changes['pce-'+this.value.id][attribute_with_merge_error[0]]['after'] = JSON.stringify(temp_new_value)
                    }


                    // Submit the changes and re-check any merge errors
                    console.log(attribute_with_merge_error[0],attribute_with_merge_error[1]);
                });
                this.reload_page();
            }
        },
        format_merge_issue_value_for_operator: function (attribute_with_merge_issue, value) {
            switch(attribute_with_merge_issue) {
                case 'crew_accepted_transfer_at':
                    return this.$root.time_string_to_date(value).toLocaleString('en-GB',{'dateStyle':'short', 'timeStyle':'short', 'hour12': true})
                case 'started_at':
                    return this.$root.time_string_to_date(value).toLocaleString('en-GB',{'dateStyle':'short', 'timeStyle':'short', 'hour12': true})
                case 'dr_agree_with_injuries':
                    return value ? 'Yes':'No';
                case 'dr_agree_with_returned_property':
                    return value ? 'Yes':'No';
                case 'dr_agree_with_returned_meds':
                    return value ? 'Yes':'No';
                case 'any_visible_injuries_start':
                    return value ? 'Yes':'No';
                case 'ra_differences':
                    return value ? 'Yes':'No';
                case 'non_standard_immobilisation_used':
                    return value ? 'Yes':'No';
                case 'any_new_visible_injuries':
                    return value ? 'Yes':'No';
                case 'feedback_score':
                    return value + ' out of 5';
                case 'initial_presentation':
                    return this.$root.presentation_options[value];
                case 'handover_presentation':
                    return this.$root.presentation_options[value];
                case 'initial_restraint':
                    return this.$root.restraint_level_options[value];
                case 'handover_restraint':
                    return this.$root.restraint_level_options[value];
                case 'into_care_dr_signature':
                    return value['name'] + ' - ' + value['date_signed'];
                case 'into_care_crew_signature':
                    return value['name'] + ' - ' + value['date_signed'];
                case 'handover_crew_signature':
                    return value['name'] + ' - ' + value['date_signed'];
                case 'handover_dr_signature':
                    return value['name'] + ' - ' + value['date_signed'];
                case 'incidents':
                    var to_return = [];
                    Object.keys(value).forEach((incident_id) => {
                        if (value[incident_id]!==null) {
                            // Get the incident info out of local storage
                            var temp_incident_info = this.$root.get_from_local_storage('incidents-'+incident_id);
                            if (temp_incident_info!==null) {
                                temp_incident_info = JSON.parse(temp_incident_info)
                            }
                            to_return.push(temp_incident_info['description']+': '+value[incident_id]['crew_notes'])
                        }
                    })
                    return to_return;
                case 'search_status':
                    switch(parseInt(value)) {
                        case 1:
                            return 'Unable to search'
                        case 2:
                            return 'Done by crew member'
                        case 3:
                            return 'Witnessed'
                        default:
                            return null
                    }

                default:
                    return value
            }
        },
    },
    data: function () {
        return {
            sign_off_confirmation_choices: {},
            input_nice_names: {
                'crew_accepted_transfer_at': 'Crew accepted transfer at',
                'started_at': 'PCE started at',
                'patient_property': 'Patient property',
                'patient_meds': 'Patient medication',
                'ra_differences_details': 'Details about differences in the patient presentation based on the risk score',
                'non_standard_immobi_description': 'Details any standard hold or immobilisation used',
                'search_status_notes': 'Search status notes',
                'search_log': 'Search log',
                'dr_agree_with_injuries': 'Does DR agrees with injuries at handover',
                'dr_agree_with_returned_property': 'Did the DR agrees with returned property at handover',
                'dr_agree_with_returned_meds': 'Did the DR agrees with returned medication at handover',
                'feedback_score': 'Service rated by DR',
                'qualitative_feedback': 'Feedback from DR',
                'feedback_provider': 'Feedback from',
                'current_journey_step_id': 'Current journey step',
                'any_visible_injuries_start': 'Any visible injuries at patient into care',
                'into_care_image_map': 'Visible injuries at patient into care',
                'patient_injuries_handover': 'New patient injuries after transfer',
                'initial_restraint': 'Initial patient restraint',
                'initial_presentation': 'Initial patient presentation',
                'handover_restraint': 'Patient restraint at handover',
                'handover_presentation': 'Patient presentation at handover',
                'into_care_dr_signature': 'DR signature at collection',
                'into_care_crew_signature': 'Crew signature at collection',
                'handover_crew_signature': 'Crew signature at handover',
                'handover_dr_signature': 'DR signature at handover',
                'ra_differences': 'Are there differences in the patient presentation based on the risk score',
                'non_standard_immobilisation_used': 'Was a non standard hold or immobilisation used',
                'search_status': 'Search status',
                'any_new_visible_injuries': 'Any new visible injuries at handover',
                'incidents': 'Incidents',
                'journey_steps': 'Journey steps',
                'journey_notes': 'Journey notes',
                'presentation_history': 'Patient presentation history',
                'restraint_history': 'Patient restraint history',
                'dr_returned_property_notes': 'Notes about returned property from DR at handover',
                'dr_returned_meds_notes': 'Notes about returned medications from DR at handover',
                }
        }
    },
    computed: {
        // is_sign_confirmation_form_valid checks each merge issue, and returns false if the operator hasn't selected a preferred version to save.
        is_sign_off_confirmation_form_valid: function () {
            var is_valid = true;
            Object.entries(this.pce_merge_errors).forEach(attribute_with_merge_error => {
                if (this.sign_off_confirmation_choices[attribute_with_merge_error[0]]===undefined) {
                    is_valid = false;
                }
            });
            return is_valid;
        },
        format_pce_merge_errors: function () {
            var to_return = {};
            Object.entries(this.pce_merge_errors).forEach(attribute_with_merge_error => {
                to_return[attribute_with_merge_error[0]] = {}
                try {
                    to_return[attribute_with_merge_error[0]]['current_value_in_db'] = JSON.parse(attribute_with_merge_error[1]['current_value_in_db']);
                    // Handle non-exception-throwing cases:
                    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                    // but... JSON.parse(null) returns null, and typeof null === "object",
                    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
                    if (!to_return[attribute_with_merge_error[0]]['current_value_in_db'] || typeof to_return[attribute_with_merge_error[0]]['current_value_in_db'] !== "object") {
                        to_return[attribute_with_merge_error[0]]['proposed_new_value'] = this.value[attribute_with_merge_error[0]]
                    }
                } catch (e) {
                    to_return[attribute_with_merge_error[0]]['current_value_in_db'] = attribute_with_merge_error[1]['current_value_in_db']
                }

                try {
                    to_return[attribute_with_merge_error[0]]['proposed_new_value'] = JSON.parse(this.value[attribute_with_merge_error[0]])
                    // Handle non-exception-throwing cases:
                    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                    // but... JSON.parse(null) returns null, and typeof null === "object",
                    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
                    if (!to_return[attribute_with_merge_error[0]]['proposed_new_value'] || typeof to_return[attribute_with_merge_error[0]]['proposed_new_value'] !== "object") {
                        to_return[attribute_with_merge_error[0]]['proposed_new_value'] = this.value[attribute_with_merge_error[0]]
                    }
                } catch (e) {
                    to_return[attribute_with_merge_error[0]]['proposed_new_value'] = this.value[attribute_with_merge_error[0]]
                }

            })
            console.log('to_return', to_return)
            return to_return;
        },
    },
    mounted: function () {
        this.reload_page();
    }
}
</script>

<style scoped>
.sign_off_confirmation {
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    margin-top: 30px;
}
.sign_off_confirmation_title {
    background: #3490dc;
    color: white;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
}
.sign_off_confirmation_body {
    font-size: 13px;
    color: #909090;
    padding: 10px;
    padding-bottom: 20px;
    user-select: none;

}
.sign_off_confirmation_choices_simple_label {
    color: #313131;
    margin-bottom: 3px;
    padding-top: 0;
}
.sign_off_confirmation_choices_simple_container {
    padding: 20px;
    background: white;
    margin-top: 0px;
    transition: background 0.1s ease-in-out;
}
.sign_off_confirmation_choices_simple_container:active {
    background: #caebff;
}
.sign_off_confirmation_choices_simple_container.selected {
    background: #caebff;
}
</style>
