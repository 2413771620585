<template>
<div>
<!--    //todo, refactor to AdminJourneyStep from AdminBookingStep -->

    <div class="row" v-if="transfer_type!=1&&transfer_type!=6"> <!--  //1 = Ward Assist, 6 = Bed Watch-->
        <input type="hidden" autocomplete="off" name="collection_step_id" :value="value.collection.id" v-if="value.collection.id!==undefined">

        <div class="col-6">
            <div class="admin_journey_step">
                <div class="row  pb-3">
                    <div class="col-4">
                        Collection Address
                    </div>
                    <div class="col-5">
                        <address-finder-component v-model="value.collection.address_id" name="collection_address_id"></address-finder-component>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        Collection Contact Number
                    </div>
                    <div class="col-5">
                        <input class="form-control" type="search" autocomplete="off" v-model="value.collection.contact_number" name="collection_contact_number">
                    </div>
                    <div class="col-3">
                        <input class="form-control" type="search" autocomplete="off" v-model="value.collection.contact_number_notes" placeholder="Notes. E.g. ext" name="collection_contact_number_notes">
                    </div>
                </div>
            </div>
            <br>
            <hr>
        </div>
    </div>
    <!--    Additional steps --- START--- -->
    <div v-if="value.otherSteps.length>0">
        <div class="row custom_step_container" v-for="(step, step_index) in value.otherSteps" :key="step.temp_id">
            <input type="hidden" autocomplete="off" :value="value.otherSteps[step_index]['id']" :name="'otherSteps['+step_index+'][id]'" v-if="value.otherSteps[step_index]['id']!==undefined">
            <input type="hidden" autocomplete="off" :value="value.otherSteps[step_index]['description']" :name="'otherSteps['+step_index+'][description]'" v-if="transfer_type==1||transfer_type==6">
            <input type="hidden" autocomplete="off" :value="value.otherSteps[step_index]['temp_id']" :name="'otherSteps['+step_index+'][temp_id]'">
            <div class="col-6">
                <div class="admin_journey_step" >
                    <br>
                    <div class="row  pb-3">
                        <div class="col-4">
                            Step {{step_index+1}} Description
                        </div>
                        <div class="col-5">
                            <input :name="'otherSteps['+step_index+'][description]'" class="form-control" type="search" autocomplete="off" v-model="value.otherSteps[step_index]['description']" v-if="transfer_type!=1&&transfer_type!=6">
                            <div v-else>
                                {{value.otherSteps[step_index]['description']}}
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3" v-if="transfer_type!=1&&transfer_type!=6">
                        <div class="col-4">
                            Step {{step_index+1}} Address
                        </div>
                        <div class="col-5">
                            <address-finder-component v-model="value.otherSteps[step_index]['address_id']" :name="'otherSteps['+step_index+'][address_id]'"></address-finder-component>
                        </div>
                    </div>
                    <div class="row" v-if="transfer_type!=1&&transfer_type!=6">
                        <div class="col-4">
                            Step {{step_index+1}} Contact Number
                        </div>
                        <div class="col-5">
                            <input class="form-control" type="search" autocomplete="off" v-model="value.otherSteps[step_index]['contact_number']" :name="'otherSteps['+step_index+'][contact_number]'">

                        </div>
                        <div class="col-3">
                            <input class="form-control" type="search" autocomplete="off" v-model="value.otherSteps[step_index]['contact_number_notes']" placeholder="Notes. E.g. ext" :name="'otherSteps['+step_index+'][contact_number_notes]'">
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <div class="col-6 align-items-center custom_step_hover_section" v-if="transfer_type!=1&&transfer_type!=6&&transfer_type!=4&&transfer_type!=10&&transfer_type!=11"> <!--  //1 = Ward Assist, 6 = Bed Watch, 4 = transfer, 10 = AWP 135, 11 = AWP 136-->
                <div class="pl-4">
                    <div class="custom_step_hover_button" :class="{'disabled':value.otherSteps.length===1}" @click="remove_step(step_index)">
                        <div class="custom_step_hover_button_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.667" height="15" viewBox="0 0 11.667 15">
                                <path id="Path_790" data-name="Path 790" d="M5.833,16.333A1.672,1.672,0,0,0,7.5,18h6.667a1.672,1.672,0,0,0,1.667-1.667v-10h-10Zm10.833-12.5H13.75L12.917,3H8.75l-.833.833H5V5.5H16.667Z" transform="translate(-5 -3)" fill="#fff"/>
                            </svg>
                        </div>
                        <div class="custom_step_hover_button_text">Remove step</div>
                    </div>
                    <br>
                    <div class="custom_step_hover_button" @click="add_step_after(step_index)">
                        <div class="custom_step_hover_button_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14 14">
                                <path id="Path_791" data-name="Path 791" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" transform="translate(-5 -5)" fill="#fff"/>
                            </svg>
                        </div>
                        <div class="custom_step_hover_button_text">New step</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!--    Additional steps --- END--- -->


    <div class="row" v-if="transfer_type!=1&&transfer_type!=6"> <!--  //1 = Ward Assist, 6 = Bed Watch-->
        <div class="col-6">
            <div class="row" v-if="value.otherSteps.length>0">
                <div class="col-6"><hr></div>
            </div>
            <br>
            <div class="admin_journey_step">
                <input type="hidden" autocomplete="off" name="destination_step_id" :value="value.destination.id" v-if="value.destination.id!==undefined">
                <div class="row pb-3" >
                    <div class="col-4">
                        Destination Address
                    </div>
                    <div class="col-5">
                        <address-finder-component v-model="value.destination.address_id" name="destination_address_id"></address-finder-component>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        Destination Contact Number
                    </div>
                    <div class="col-5">
                        <input class="form-control" type="search" autocomplete="off" v-model="value.destination.contact_number" name="destination_contact_number">
                    </div>
                    <div class="col-3">
                        <input class="form-control" type="search" autocomplete="off" v-model="value.destination.contact_number_notes" name="destination_contact_number_notes" placeholder="Notes. E.g. ext">
                    </div>
                </div>
            </div>
        </div>
    </div>





</div>
</template>

<script>
export default {
    props: {
        transfer_type: [Number,String],
        value: {
            type: Object,
            required: true,
        },
    },
    watch: {
        transfer_type: function () {
            this.updated_transfer_type();
        },
    },
    data: function () {
        return {

        }
    },
    mounted: function () {
        if (this.value.otherSteps[0]!==undefined&&this.value.otherSteps[0]['default']) {
            this.updated_transfer_type();
        }
    },
    methods: {
        updated_transfer_type: function () {
            console.log('Transfer type change')
            this.value.can_add_other_steps = true;

            if (this.transfer_type == 1) { //Ward Assist
                this.value.otherSteps = [
                    {
                        'description':'Ward Assist',
                        'address_id':null,
                        'contact_number':null,
                        'contact_number_notes':null,
                        'temp_id': Math.random(), // Used to make sure vue stays reactive
                    }
                ]
            } else if (this.transfer_type == 2 || this.transfer_type == 3 || this.transfer_type == 9 || this.transfer_type == 8) { //135, 136, MOJ, or Section Recall
                this.value.otherSteps = [
                    {
                        'description':'Suite',
                        'address_id':null,
                        'contact_number':null,
                        'contact_number_notes':null,
                        'temp_id': Math.random(), // Used to make sure vue stays reactive
                    }
                ]
            } else if (this.transfer_type == 4 || this.transfer_type == 10 || this.transfer_type == 11) { //Transfer, AWP 135, or AWP 136
                this.value.otherSteps = []
            } else if (this.transfer_type == 5 || this.transfer_type == 9 || this.transfer_type == 7) { //W&R Appointment, MOJ, or Community Assessment
                this.value.otherSteps = [
                    {
                        'description':'',
                        'address_id':null,
                        'contact_number':null,
                        'contact_number_notes':null,
                        'temp_id': Math.random(), // Used to make sure vue stays reactive
                    }
                ]
            } else if (this.transfer_type == 6) { // Bed Watch

                this.value.otherSteps = [
                    {
                        'description':'Waiting at Appointment',
                        'address_id':null,
                        'contact_number':null,
                        'contact_number_notes':null,
                        'temp_id': Math.random(), // Used to make sure vue stays reactive
                    }
                ]
            }
        },
        remove_step: function (index) {
            if (this.value.otherSteps.length>1) {
                this.value.otherSteps.splice(index, 1)
            }
        },
        add_step_after: function (add_after_index) {
            this.value.otherSteps.splice(add_after_index+1, 0, {
                'description':'',
                'address_id':null,
                'contact_number':null,
                'contact_number_notes':null,
                'temp_id': Math.random(), // Used to make sure vue stays reactive
            });
        },

    }
}
</script>

<style scoped>
.custom_step_hover_section {
    display: none;
}
.custom_step_container:hover .custom_step_hover_section {
    display: flex;
}
.custom_step_hover_button {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.custom_step_hover_button_icon {
    background-color: #65C7DE;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.custom_step_hover_button_text {
    color: #65C7DE;
    font-weight: bold;
    font-size: 16px;
    padding-left: 10px;
    user-select: none;
}
.custom_step_hover_button.disabled {
    cursor: default;
}
.custom_step_hover_button.disabled .custom_step_hover_button_icon {
    background-color: #9CDBE9;
}
.custom_step_hover_button.disabled .custom_step_hover_button_text {
    color: #9CDBE9;
}
</style>
