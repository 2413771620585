<template>
    <div>
        <!--        current_journey_step_id ---START---  -->
        <div v-if="attr_name==='current_journey_step_id'" class="sign_off_choice pl-3 ">
            <div v-for="journey_step in $parent.value.journey_steps" v-if="attr_value==journey_step.id">
                {{journey_step.description || journey_step.type}}
            </div>
        </div>
        <!--        current_journey_step_id ---END---  -->

        <!--        Image Map attribute type ---START---  -->
        <div v-else-if="attr_name==='into_care_image_map'||attr_name==='patient_injuries_handover'" class="sign_off_choice pl-3 ">
            <ul v-if="attr_value.length>=1" >
                <li v-for="attr_value_item in attr_value">{{attr_value_item['title']}}<span v-if="attr_value_item['notes']!==''">: {{attr_value_item['notes']}}</span></li>
            </ul>
        </div>
        <!--        Image Map attribute type ---END---  -->

        <!--        presentation_history attribute type ---START---  -->
        <div v-else-if="attr_name==='presentation_history'" class="sign_off_choice pl-3 ">
            <ul >
                <li v-for="attr_value_item in attr_value">

                    <b>{{$root.presentation_options[attr_value_item['value']]}}:</b>
                    {{$root.time_string_to_date(attr_value_item['started_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}} -
                    <span v-if="attr_value_item['ended_at']!==null">
                        {{$root.time_string_to_date(attr_value_item['ended_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}}
                    </span>
                    <span v-else>
                        Ongoing
                    </span>
                </li>
            </ul>
        </div>
        <!--        presentation_history attribute type ---END---  -->

        <!--        restraint_history attribute type ---START---  -->
        <div v-else-if="attr_name==='restraint_history'" class="sign_off_choice pl-3 ">
            <ul >
                <li v-for="attr_value_item in attr_value">
                    <b>{{$root.restraint_level_options[attr_value_item['value']]}}:</b>
                    {{$root.time_string_to_date(attr_value_item['started_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}} -
                    <span v-if="attr_value_item['ended_at']!==null">
                        {{$root.time_string_to_date(attr_value_item['ended_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}}
                    </span>
                    <span v-else>
                        Ongoing
                    </span>
                </li>
            </ul>
        </div>
        <!--        restraint_history attribute type ---END---  -->

        <!--        journey_steps attribute type ---START---  -->
        <div v-else-if="attr_name==='journey_steps'" class="sign_off_choice pl-3 ">
            <ul >
                <li v-for="attr_value_item in attr_value">
                    <b v-if="attr_value_item['type']==='collection'">Collection</b>
                    <b v-else-if="attr_value_item['type']==='destination'">Destination</b>
                    <b v-else>{{attr_value_item['description']}}</b>
                    <span v-if="attr_value_item['started_at']===null">
                        Not started
                    </span>
                    <span v-else>
                        {{$root.time_string_to_date(attr_value_item['started_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}} -
                        <span v-if="attr_value_item['ended_at']!==null">
                            {{$root.time_string_to_date(attr_value_item['ended_at']).toLocaleString('en-GB',{'timeStyle':'short', 'hour12': true})}}
                        </span>
                        <span v-else>
                            Ongoing
                        </span>
                    </span>

                </li>
            </ul>
        </div>
        <!--        journey_steps attribute type ---END---  -->

        <!--        journey_notes attribute type ---START---  -->
        <div v-else-if="attr_name==='journey_notes'" class="sign_off_choice pl-3 ">
            <ul >
                <li v-for="attr_value_item in attr_value">
                    {{attr_value_item['content']}}
                </li>
            </ul>
        </div>
        <!--        journey_notes attribute type ---END---  -->

        <!--   Generic attribute type ---START--- -->
        <div v-else>
            <div v-if="typeof attr_value === 'object'" :value="attr_value">
                <div v-if="Array.isArray(attr_value)" class="sign_off_choice">
                    <ul v-if="attr_value.length>=1" >
                        <li v-for="attr_value_item in attr_value">{{attr_value_item}}</li>
                    </ul>
                    <div v-else ><i class="pl-3 text-muted font-weight-bold">BLANK</i></div>
                </div>
            </div>

            <input v-else class="form-control sign_off_confirmation_choices_simple_value" :value="attr_value" disabled >
        </div>
        <!--   Generic attribute type ---END--- -->

    </div>

</template>

<script>
export default {
    props:['attr_name', 'attr_value'],
    methods: {
        display_image_map_point_as_text: function (image_map_point) {
            image_map_point = JSON.parse(image_map_point);
            return image_map_point['title']+' - '+image_map_point['notes'];
        },
    },
}
</script>

<style scoped>

.sign_off_choice {
    background: #e9ecef;
    padding: 10px 0px;
    border-radius: 4px;
    border: 1px solid #ced4da;
}
</style>
