<template>
    <div style="width: 100%;">
        <ais-instant-search :search-client="searchClient" index-name="pce">
            <ais-search-box ref="ais-search-box">
                <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
                    <div class="topbarSearchInput">
                        <label class="text-muted material-icons align-self-center pl-3" for="topNavSearchBarInput">search</label>
                        <input
                            @blur="$root.searchBlur"
                            autocomplete="off"
                            type="search"
                            v-model="currentRefinement"
                            @input="refine($event.currentTarget.value); $root.updateSearchQ($event.currentTarget.value);"
                            id="topNavSearchBarInput" class="align-self-center pl-3 d-inline-block"
                            style="font-size: 20px;border: none;outline: none !important;width: calc(100% - 60px);"
                            placeholder="Search">
                    </div>
                    <span :hidden="!isSearchStalled">Loading...</span>
                </div>
            </ais-search-box>
            <div class="topbarSearchResults" v-if="!$root.hideResults">

                <div class="topbarSearchResultsList">

                    <!-- PCE results START -->
                    <ais-index index-name="pce">
                        <ais-hits>
                            <div slot-scope="{ items }">
                                <a :href="'/pces/'+item.id" v-for="item in items" :key="item.objectID"
                                   class="topbarSearchResultItem">
                                    <div class="material-icons topbarSearchResultItem_icon">view_list</div>
                                    <div class="topbarSearchResultItem_text"><b>{{ item.sc_number }}</b>
                                        <div v-for="(highlight, highlight_attr_name)  in item._highlightResult">
                                            <div v-if="highlight.matchLevel=='full'"
                                                 class="topbarSearchResultItem_highlight">
                                                {{ nice_attr_names['pce'][highlight_attr_name] }}:<span class="pl-1"
                                                                                                        v-html="highlight.value"></span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ais-hits>
                    </ais-index>
                    <!-- PCE results END -->

                    <!-- User results START -->
                    <ais-index index-name="users">
                        <ais-hits>
                            <div slot-scope="{ items }">
                                <a :href="'/user/'+item.id" v-for="item in items" :key="item.objectID"
                                   class="topbarSearchResultItem">
                                    <div class="material-icons topbarSearchResultItem_icon">person</div>
                                    <div class="topbarSearchResultItem_text"><b>{{ item.name }}</b>
                                        <div v-for="(highlight, highlight_attr_name)  in item._highlightResult">
                                            <div v-if="highlight.matchLevel=='full'"
                                                 class="topbarSearchResultItem_highlight">
                                                {{ nice_attr_names['user'][highlight_attr_name] }}:<span class="pl-1"
                                                                                                         v-html="highlight.value"></span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ais-hits>
                    </ais-index>
                    <!-- User results END -->

                    <!-- Devices results START -->
                    <ais-index index-name="device">
                        <ais-hits>
                            <div slot-scope="{ items }">
                                <a :href="'/devices/'+item.id" v-for="item in items" :key="item.objectID"
                                   class="topbarSearchResultItem">
                                    <div class="material-icons topbarSearchResultItem_icon">smartphone</div>
                                    <div class="topbarSearchResultItem_text"><b>{{ item.name }}</b>
                                        <div v-for="(highlight, highlight_attr_name)  in item._highlightResult">
                                            <div v-if="highlight.matchLevel=='full'"
                                                 class="topbarSearchResultItem_highlight">
                                                {{ nice_attr_names['device'][highlight_attr_name] }}:<span class="pl-1"
                                                                                                           v-html="highlight.value"></span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ais-hits>
                    </ais-index>
                    <!-- Devices results END -->


                </div>

            </div>
            <!-- Other search components go here -->
        </ais-instant-search>
    </div>
</template>

<script>
export default {
    mounted: function () {
        // console.log('$root.hideResults',this.$root.hideResults);
    },
    data: function () {
        return {
            searchQ: null,
            searchClient: algoliasearch(
                process.env.MIX_ALGOLIA_APP_ID,
                process.env.MIX_ALGOLIA_SEARCH
            ),
            nice_attr_names: {
                'device': {
                    'setup_by': 'Setup by',
                    'name': 'Device name',
                    'id': 'Device ID',
                },
                'user': {
                    'name': 'User\'s name',
                    'email': 'User\'s email',
                    'phone': 'User\'s phone',
                    'id': 'User ID',
                },
                'pce': {
                    'sc_number': 'SC number',
                    'nhs_number': 'NHS number',
                    'patient_name': 'Patient name',
                    'approver_name': 'Approver name',
                    'booker_name': 'Booker name',
                    'budget_code_for_invoicing': 'Budget code',
                    'hub': 'Hub',
                    'deployed_name': 'Deployed name',
                    'purchase_order_number': 'Purchase order number',
                    'collection_timestamp': 'Requested collection time',
                    'trust_name': 'Trust',
                    'type': 'Type',
                }
            },
        }
    },
}
</script>

<style scoped>
.topbarSearchResultItem_highlight {
    padding-left: 10px;
    color: #adadad !important;
}

.topbarSearchResultItem_highlight mark {
    background-color: transparent;
    padding: 0px;
}

.topbarSearchResultItem {
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: #3a4570 !important;
}

.topbarSearchResultItem_text {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.topbarSearchResultItem:hover {
    background: #d0eef5;
}

.topbarSearchInput {
    height: 60px;
    display: flex;
    align-items: center;
}

.topbarSearchResults {
    background-color: white;
    width: 100%;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.10);
    border-top: 1px solid #f1f1f1;
}

.topbarSearchResultsList {
    position: fixed;
    top: 60px;
    left: 250px;
    width: calc(100% - 250px);
    padding: 10px 25px;
    background-color: white;
}

.topbarSearchResultsPageButton {
    padding: 10px 15px;
    cursor: pointer;
}
</style>
