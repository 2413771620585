<template>
<div>
    <div v-for="(step, index) in value.steps" class="node_container" :class="{'disabled':step.disabled, 'done':(step.completed&&value.active_step!==step.label), 'active':(value.active_step===step.label)}">
        <div @click="changeStep(index)" >
            <div class="node_dot_container node_dot">
                <div class="node_white_ring node_dot">
                    <div class="node_color_ring node_dot">
                        <div class="node_center node_dot">
                        </div>
                    </div>
                </div>
            </div>


            <div class="node_step_label">
                {{ step.label }}
            </div>

        </div>

    </div>


</div>
</template>

<script>
export default {
    props: {
        'value': {
            type: Object,
            required: true,
        },
    },
    methods: {
        changeStep: function (new_step_index) {
            if (!this.value.steps[new_step_index].disabled) {
                var last_step = this.value.active_step
                this.value.active_step = this.value.steps[new_step_index].label;
                this.$emit('change', {
                    'to': this.value.steps[new_step_index].label,
                    'from': last_step
                })
            }

        }
    },
}
</script>

<style scoped>
    .node_container {
        display: inline-flex;
        width: 120px;
        justify-content: center;
        padding: 0 10px;
        text-align: center;
    }
    .node_dot {
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #65C7DE;
    }
    .node_dot_container {
        width: 60px;
        height: 60px;
    }
    .node_white_ring {
        width: 50px;
        height: 50px;
        background-color: white !important;
    }
    .node_color_ring {
        width: 46px;
        height: 46px;
    }
    .node_center {
        width: 44px;
        height: 44px;
        background-color: white !important;
    }
    .node_step_label {
        margin-top: 7px;
    }
    .node_container.active .node_dot {
        background-color: #65DE71;
    }

    .node_container.done .node_dot {
        background-color: #65DE71;
    }
    .node_container.done .node_center {
        background-color: #65DE71 !important;
    }
    .node_container.disabled .node_dot {
        background-color: #989898;
    }
    .node_step_label {
        color: #65C7DE;
    }
    .node_container.disabled .node_step_label {
        color: #989898;
    }
    .node_container.done .node_step_label {
        color: #65DE71;
    }
    .node_container.active .node_step_label {
        font-weight: bold;
        color: #65DE71;
    }
</style>
