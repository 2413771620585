<template>
    <div id="cva_index">
        <div id="appHomeBanner">
            <div id="appBannerOverlay">
                <div id="home_image_overlay"></div>
                <div id="home_color_overlay">
                    <h1 id="homepage_title" v-if="$root.currentUser!==null">Hello {{$root.currentUser.name.split(' ')[0]}}</h1>
                </div>
            </div>
        </div>
        <div id="pcr_list">



            <h2 style="margin: 20px;">Post CVA Inspection Form</h2>
            <div class="progress-box-container">
                <div class="progress-box" :class="{'prog_active':cva_inspection.step===1}">
                    <div>
                        <div class="progress-box-title text-primary">{{ cva_inspection.steps.one.title }}</div>
                        <div class="progress-box-step">Step {{ cva_inspection.steps.one.number }}</div>
                    </div>
                </div>
                &nbsp;
                <div class="progress-box" :class="{'prog_active':cva_inspection.step===2}">
                    <div>
                        <div class="progress-box-title text-primary">{{ cva_inspection.steps.two.title }}</div>
                        <div class="progress-box-step">Step {{ cva_inspection.steps.two.number }}</div>
                    </div>
                </div>
                &nbsp;
                <div class="progress-box" :class="{'prog_active':cva_inspection.step===3}">
                    <div>
                        <div class="progress-box-title text-primary">{{ cva_inspection.steps.three.title }}</div>
                        <div class="progress-box-step">Step {{ cva_inspection.steps.three.number }}</div>
                    </div>
                </div>
            </div>
            <div class="pcr_list_item">
                <div class="cva_card">
                    <form>
                        <div v-if="cva_inspection.step === 1" class="pce_card_content">

                            <h5>Odometer reading:</h5>
                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Please record the ending Odometer reading for the vehicle</p>
                                </div>
                                <div>
                                    <input type="number" class="form-control" v-model="cva_inspection.odometer" placeholder="Comments">
                                </div>
                            </div>
                            <h5>Vehicle Maintenance:</h5>
                            <hr>
                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Does the vehicle require any maintenance? If Yes, please describe</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.maintenance[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.maintenance[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Have any vehicle warning lights appeared during your CVA. If Yes, please describe</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.warn_light[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.warn_light[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Has there been any physical damage to your vehicle during your CVA? If Yes, please describe</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.damage[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.damage[1]" placeholder="Comments">
                                </div>
                            </div>

                        </div>
                        <div v-if="cva_inspection.step === 2" class="pce_card_content">

                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Are you leaving the vehicle in a clean state ready for the next crew?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.hygiene.clean[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Does the vehicle require an infection control deep clean. If Yes please give reasons</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.hygiene.infection[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.hygiene.infection[1]" placeholder="Comments">
                                </div>
                            </div>
                        </div>
                        <div v-if="cva_inspection.step === 3" class="pce_card_content">
                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Has your kit bag been used and the seal broken to access contents?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.kitbag.broken[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" v-model="cva_inspection.kitbag.broken[1]" class="form-control"  placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Did the kit bag contain the required PPE</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.kitbag.required[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                            </div>

                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Aside from PPE, did the kit bag contain what you required?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.kitbag.ppe[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                            </div>

                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Does the kit bag have consumables that require replenishing / restocking?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.kitbag.consume[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                            </div>

                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Text entry box to input what consumables required</p>
                                </div>
                                <div>
                                    <input type="text" v-model="cva_inspection.kitbag.consume[1]" class="form-control" placeholder="Comments">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="pcr_list_item_status justify-content-end">
                    <div v-if="cva_inspection.step!==1" @click.prevent="prev()" class="btn btn-primary btn-sm">Previous</div>&nbsp;&nbsp;
                    <button v-if="cva_inspection.step===3" :disabled="!isStepComplete()" @click.prevent="submit()" class="btn btn-primary btn-sm">Submit</button>
                    <button v-else @click.prevent="next()" :disabled="!isStepComplete()" class="btn btn-primary btn-sm">Save and Continue</button>
                </div>
            </div>
        </div>

        <center>
            <div class="btn btn-primary" style="width: 300px;" @click="$root.page='pce_index'; currentCVA=null">Exit CVA Inspection</div>
        </center>

    </div>
</template>

<script>
export default {
    name: "PostCVAInspection",
    methods: {
        isStepComplete(){
            if(this.cva_inspection.step === 1){
                if (this.cva_inspection.damage[0] !== null && this.cva_inspection.maintenance[0] !== null) {
                    return true;
                }
            }
            if(this.cva_inspection.step === 2){
                if (this.cva_inspection.hygiene.clean[0] !== null && this.cva_inspection.hygiene.infection[0] !== null) {
                    return true;
                }
            }
            if(this.cva_inspection.step === 3){
                if (this.cva_inspection.kitbag.broken[0] !== null && this.cva_inspection.kitbag.consume[0] !== null) {
                    return true;
                }
            }
        },
        prev() {
            this.cva_inspection.step--;
        },
        next() {
            this.cva_inspection.step++;
        },
        submit() {
            // Post data.
            let inspection = this.cva_inspection
            axios({
                method: 'post',
                url: this.$root.endpoint+'/api/cvas/'+this.$root.currentCVA.id+'/inspection',
                data: {
                    post: 1,
                    inspection: inspection
                }
            }).then(response => {
                this.$root.page='pce_index'
            });
        },
        getCva(cvaId) {
            axios({
                method: 'get',
                url: this.$root.endpoint+'/api/cva/' + cvaId
            }).then(response => {
                if(response.data.post_cva !== null) {
                    this.cva_inspection = response.data.post_cva
                    this.cva_inspection.step = 1
                    return this.cva_inspection
                }
            });
        }
    },
    computed: {
        cvaID() {
            return this.$root.currentCVA.id;
        }
    },
    mounted(){
        this.getCva(this.cvaID)
    },
    data() {
        return {
            cva_inspection: {
                step: 1,
                steps: {
                    one: {
                        title: 'Vehicle Maintenance',
                        number: 1,
                        active: true,
                        completed: false
                    },
                    two: {
                        title: 'Vehicle Hygiene',
                        number: 2,
                        active: null,
                        completed: false
                    },
                    three: {
                        title: 'Kit Bags',
                        number: 3,
                        active: null,
                        completed: false
                    }
                },
                maintenance: {
                    0: null,
                    1: null
                },
                warn_light: {
                    0: null,
                    1: null
                },
                damage: {
                    0: null,
                    1: null
                },
                hygiene: {
                    clean: {
                        0: null,
                        1: null
                    },
                    infection: {
                        0: null,
                        1: null
                    }
                },
                kitbag: {
                    broken: {
                        0: null,
                        1: null
                    },
                    ppe: {
                        0: null,
                        1: null
                    },
                    required: {
                        0: null,
                        1: null
                    },
                    consume: {
                        0: null,
                        1: null
                    }
                },
            }
        }
    },
}
</script>

<style scoped>
#appHomeBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #84d1f5;
    background-image: url("assets/img/app_top_img.jpg");
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#home_color_overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg, transparent 100px, #eeeeee 100px);
}
#home_image_overlay {
    background-image: url("assets/img/app_home_colour_overlay.png");
    width: 100px;
    height: 88px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
}

#appBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
}
#homepage_title {
    line-height: 45px;
    font-size: 50px;
    margin-left: 100px;
    margin-top: 60px;
    font-weight: bold;
    color: black;
}
body {
    min-height: 100%;
    background-color: #eeeeee !important;
}

#pcr_list {
    margin-top: 150px;
}
.pcr_list_item {
    background-color: white;
    border-radius: 20px;
    border: 1px solid #D4D4D5;
    margin: 20px;
}
.pcr_list_item_title {
    font-size: 20px;
    font-weight: bold;
    color: #3B3B3B;
}
.pcr_list_item_body {
    padding: 20px;
    color: #9D9D9D;
}
.pcr_list_item_status {
    color: rgb(204, 157, 46);
    border-top: 1px solid #DADADB;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}
.pce_card_content > * {
    margin: 20px 0px 20px 0px;
}
.pce_card_content h2 {
    font-weight: 600;
}
.pce_card_content h5 {
    font-weight: 600;
    margin: 30px 0px 30px 0px;
}
.cva_card {
    background-color: white;
    box-shadow: 0 0 4px #00000069;
    min-height: 500px;
    border-radius: 20px;
}
.progress-box-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.progress-box{
    min-width: 160px;
    border-radius: 15px;
    margin: 0px 10px 0px 10px;
}

/*Unfortunately this is duplicated in Vehicle.vue update in both places*/
.pre_form_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pre_form_row p {
    margin: 0 !important;
}
/*row question*/
.three_items > *:first-child,
.vehicle_hygiene.faces > *:first-child {
    width: 160px;
}

.two_items > *:first-child,
.vehicle_hygiene.two_items > *:first-child {
    /*160 + 160 + 10*/
    width: 330px;
}
.three_items > *:first-child,
.three_items > *:nth-child(2),
.two_items > *:first-child {
    margin-right: 10px;
}

/*row toggle*/
.three_items > *:nth-child(2) {
    width: 160px;
}
/*row answer input*/
.three_items > *:last-child,
.two_items > *:last-child {
    flex: 1;
}
.vehicle_hygiene.faces > *:nth-last-child(-n+5) {
    flex: 1;
}
</style>
