<template>
    <div id="cva_index">
        <div id="appHomeBanner">
            <div id="appBannerOverlay">
                <div id="home_image_overlay"></div>
                <div id="home_color_overlay">
                    <h1 id="homepage_title" v-if="$root.currentUser!==null">Hello {{$root.currentUser.name.split(' ')[0]}}</h1>
                </div>
            </div>
        </div>
        <div id="pcr_list">

            <h2 style="margin: 20px;">Pre CVA Inspection Form</h2>
            <div class="progress-box-container">
                    <div class="progress-box" :class="{'prog_active':cva_inspection.step===1}">
                        <div>
                            <div class="progress-box-title text-primary">{{ cva_inspection.steps.one.title }}</div>
                            <div class="progress-box-step">Step {{ cva_inspection.steps.one.number }}</div>
                        </div>
                    </div>

                    <div class="progress-box" :class="{'prog_active':cva_inspection.step===2}">
                        <div>
                            <div class="progress-box-title text-primary">{{ cva_inspection.steps.two.title }}</div>
                            <div class="progress-box-step">Step {{ cva_inspection.steps.two.number }}</div>
                        </div>
                    </div>

                    <div class="progress-box" :class="{'prog_active':cva_inspection.step===3}">
                        <div>
                            <div class="progress-box-title text-primary">{{ cva_inspection.steps.three.title }}</div>
                            <div class="progress-box-step">Step {{ cva_inspection.steps.three.number }}</div>
                        </div>
                    </div>
            </div>
            <div class="pcr_list_item">
                <div class="cva_card">
                    <form>
                        <div v-if="cva_inspection.step === 1" class="pce_card_content">
                            <h5>Odometer reading:</h5>
                            <div class="pre_form_row two_items">
                                <div>
                                    <p>Please record the starting Odometer reading for the vehicle</p>
                                </div>
                                <div>
                                    <input type="number" class="form-control" v-model="cva_inspection.odometer" placeholder="Odometer reading">
                                </div>
                            </div>
                            <h5>General Checks:</h5>
                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are there any warning lights showing?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.warn_light[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.warn_light[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Is the horn working?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.horn[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.horn[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Is there a fuel card for this vehicle?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.fuel_card[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.fuel_card[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Does the battery appear to be working properly?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.battery[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.battery[1]" placeholder="Comments">
                                </div>
                            </div>

                            <h5>Check the windscreen for:</h5>
                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are there any cracks in the windscreen?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.windscreen.crack[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.windscreen.crack[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are the windscreen wipers functioning?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.windscreen.wipers[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.windscreen.wipers[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Check for screen wash</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.windscreen.wash[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.windscreen.wash[1]" placeholder="Comments">
                                </div>
                            </div>

                            <h5>Check the following lights are working:</h5>
                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Headlights full and dipped</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.lights.head[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.lights.head[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Brake lights</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.lights.break[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.lights.break[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Side lights</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.lights.side[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.lights.side[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Indicators</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.lights.indicators[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.lights.indicators[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Interior Lights</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.lights.interior[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.lights.interior[1]" placeholder="Comments">
                                </div>
                            </div>

                            <h5>Wheels checks:</h5>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Do any tyres look flat or low in pressure?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.wheels.tire_pressure[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.wheels.tire_pressure[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are any tyres close to the minimum tread (2mm)?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.wheels.tire_thread[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.wheels.tire_thread[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are there missing wheel caps / hub caps?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.wheels.hub_cap[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.wheels.hub_cap[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Is there any damage to any of the wheel rims?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.wheels.rims[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.wheels.rims[1]" placeholder="Comments">
                                </div>
                            </div>

                            <h5>Vehicle Appearance:</h5>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Are the mirrors clear and un damaged?</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.appearance.mirrors[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.appearance.mirrors[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="pre_form_row three_items">
                                <div>
                                    <p>Is there any damage to other external parts such as steps, aerials, handles or trim? (Use the body map to show it)</p>
                                </div>
                                <div>
                                    <option-toggle v-model="cva_inspection.appearance.scratch[0]" :toggle_options="['No','Yes']"></option-toggle>
                                </div>
                                <div>
                                    <input type="text" class="form-control" v-model="cva_inspection.appearance.scratch[1]" placeholder="Comments">
                                </div>
                            </div>

                            <div class="row">
                                <app-pce-image-map image="assets/img/vehicle_front.png" component_name="vehicle_front" ref="vehicle_front" set_mark_name="Damage" v-model="cva_inspection.body_map.front"></app-pce-image-map>
                                <app-pce-image-map image="assets/img/vehicle_back.png" component_name="vehicle_back" ref="vehicle_back" set_mark_name="Damage" v-model="cva_inspection.body_map.back"></app-pce-image-map>
                            </div>

                            <div class="row">
                                <app-pce-image-map image="assets/img/vehicle_right.png" component_name="vehicle_right" ref="vehicle_right" set_mark_name="Damage" v-model="cva_inspection.body_map.right"></app-pce-image-map>
                                <app-pce-image-map image="assets/img/vehicle_left.png" component_name="vehicle_left" ref="vehicle_left" set_mark_name="Damage" v-model="cva_inspection.body_map.left"></app-pce-image-map>
                            </div>
                        </div>
                        <!-- ============================== -->
                        <!-- Step 2 Vehicle hygiene - START -->
                        <!-- ============================== -->
                        <div v-if="cva_inspection.step === 2" class="pce_card_content">

                            <div class="pre_form_row vehicle_hygiene two_items">
                                <p>Has the vehicle been left clean by the previous crew and ready for your use?</p>
                                <option-toggle v-model="cva_inspection.vehicle_hygiene.left_clean" :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row vehicle_hygiene faces" :key="cva_inspection.vehicle_hygiene.external">
                                <p>Score the vehicle external cleanliness</p>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.external===1}" @click="cva_inspection.vehicle_hygiene.external=1">
                                        <img src="assets/img/very-bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Very bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.external===2}" @click="cva_inspection.vehicle_hygiene.external=2">
                                        <img src="assets/img/bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.external===3}" @click="cva_inspection.vehicle_hygiene.external=3">
                                        <img src="assets/img/ok-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Ok</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.external===4}" @click="cva_inspection.vehicle_hygiene.external=4">
                                        <img src="assets/img/good-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Good</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.external===5}" @click="cva_inspection.vehicle_hygiene.external=5">
                                        <img src="assets/img/excellent-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Excellent</div>
                                    </div>
                                </div>
                            </div>

                            <div class="pre_form_row vehicle_hygiene faces" :key="cva_inspection.vehicle_hygiene.internal">
                                <p>Score the vehicle internal cleanliness</p>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.internal===1}" @click="cva_inspection.vehicle_hygiene.internal=1">
                                        <img src="assets/img/very-bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Very bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.internal===2}" @click="cva_inspection.vehicle_hygiene.internal=2">
                                        <img src="assets/img/bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.internal===3}" @click="cva_inspection.vehicle_hygiene.internal=3">
                                        <img src="assets/img/ok-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Ok</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.internal===4}" @click="cva_inspection.vehicle_hygiene.internal=4">
                                        <img src="assets/img/good-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Good</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.internal===5}" @click="cva_inspection.vehicle_hygiene.internal=5">
                                        <img src="assets/img/excellent-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Excellent</div>
                                    </div>
                                </div>
                            </div>

                            <div class="pre_form_row vehicle_hygiene faces" :key="cva_inspection.vehicle_hygiene.window">
                                <p>Score the vehicle on window cleanliness</p>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.window===1}" @click="cva_inspection.vehicle_hygiene.window=1">
                                        <img src="assets/img/very-bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Very bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.window===2}" @click="cva_inspection.vehicle_hygiene.window=2">
                                        <img src="assets/img/bad-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Bad</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.window===3}" @click="cva_inspection.vehicle_hygiene.window=3">
                                        <img src="assets/img/ok-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Ok</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.window===4}" @click="cva_inspection.vehicle_hygiene.window=4">
                                        <img src="assets/img/good-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Good</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="feedback_face_container" :class="{'active':cva_inspection.vehicle_hygiene.window===5}" @click="cva_inspection.vehicle_hygiene.window=5">
                                        <img src="assets/img/excellent-face.png" class="feedback_face_icon">
                                        <div class="feedback_face_description">Excellent</div>
                                    </div>
                                </div>
                            </div>

                            <div class="pre_form_row vehicle_hygiene two_items">
                                <p>Is the vehicle free from litter and rubbish</p>
                                <option-toggle v-model="cva_inspection.hygiene.clean" :toggle_options="['No','Yes']"></option-toggle>
                            </div>
                        </div>
                        <!-- ============================ -->
                        <!-- Step 2 Vehicle hygiene - END -->
                        <!-- ============================ -->

                        <!-- ======================= -->
                        <!-- Step 3 Kit bags - START -->
                        <!-- ======================= -->
                        <div v-if="cva_inspection.step === 3" class="pce_card_content">

                            <h5>Kit bag:</h5>

                            <div class="pre_form_row two_items">
                                <p>Please record your kit bag number</p>
                                <input type="text" v-model="cva_inspection.kitbag.number" class="form-control" placeholder="Kitbag number">
                            </div>


                            <h5>Restraints:</h5>

                            <div class="pre_form_row two_items">
                                <p>Please record the serial numbers of your hinged handcuffs</p>
                                <input type="text" v-model="cva_inspection.kitbag.handcuff" class="form-control" placeholder="Handcuffs serial number">
                            </div>

                            <div class="pre_form_row two_items">
                                <p>Are the handcuffs in working order?</p>
                                <option-toggle v-model="cva_inspection.kitbag.handcuff_working"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row two_items">
                                <p>Does the kitbag contain leg restraint straps?</p>
                                <option-toggle v-model="cva_inspection.kitbag.leg_straps"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row two_items">
                                <p>Is the metal detector wand in working order?</p>
                                <option-toggle v-model="cva_inspection.kitbag.metaldetector"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <h5>Defibrillator:</h5>

                            <div class="pre_form_row two_items">
                                <p>Is the defibrillator charged?</p>
                                <option-toggle v-model="cva_inspection.kitbag.defib"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row two_items">
                                <p>Is the certification for the defibrillator showing as in date?</p>
                                <option-toggle v-model="cva_inspection.kitbag.defib_date"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row two_items">
                                <p>Is the certification for the defibrillator pads showing as in date?</p>
                                <option-toggle v-model="cva_inspection.kitbag.defib_pad_date"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                            <div class="pre_form_row two_items">
                                <p>If 'No' on any of the defibrillator questions please confirm the serial number of this defibrillator (on the back of the device)</p>
                                <input type="text" v-model="cva_inspection.kitbag.defib_serialnumber" class="form-control" placeholder="Defibrillator number">
                            </div>
                            <br /><br />
                            <div class="pre_form_row two_items">
                                <p>Once checks have been made, please confirm you have resealed the kit bag with a new seal</p>
                                <option-toggle v-model="cva_inspection.kitbag.seal"  :toggle_options="['No','Yes']"></option-toggle>
                            </div>

                        </div>
                        <!-- ===================== -->
                        <!-- Step 3 Kit bags - END -->
                        <!-- ===================== -->
                    </form>
                </div>
                <div class="pcr_list_item_status justify-content-end">
                    <div v-if="cva_inspection.step!==1" @click.prevent="prev()" class="btn btn-primary btn-sm">Previous</div>&nbsp;&nbsp;
                    <button v-if="cva_inspection.step===3" :disabled="!isStepComplete()" @click.prevent="submit()" class="btn btn-primary btn-sm">Submit</button>
                    <button v-else :disabled="!isStepComplete()" @click.prevent="next()" class="btn btn-primary btn-sm">Save and Continue</button>
                </div>
            </div>
        </div>

        <center>
            <div class="btn btn-primary" style="width: 300px;" @click="$root.page='pce_index'; currentCVA=null">Exit CVA Inspection</div>
        </center>

    </div>
</template>

<script>
export default {
    name: "VehicleInspection",
    props: {
        'value':String,
        'set_mark_name':String,
        'component_name':String,
        "image":String,
        'no_offset':Boolean,
        'block_edit':Boolean,
        'use_position':Boolean
    },
    methods: {
        isStepComplete(){
            if(this.cva_inspection.step === 1){
                if (this.cva_inspection.warn_light[0] !== null && this.cva_inspection.horn[0] !== null && this.cva_inspection.appearance.scratch[0] !== null) {
                    return true;
                }
            }
            if(this.cva_inspection.step === 2){
                if (this.cva_inspection.hygiene.clean !== null && this.cva_inspection.vehicle_hygiene.left_clean !== null) {
                    return true;
                }
            }
            if(this.cva_inspection.step === 3){
                if (this.cva_inspection.kitbag.number !== null && this.cva_inspection.defib_pad_date !== null && this.cva_inspection.kitbag.seal !== null) {
                    return true;
                }
            }
        },
        prev() {
            this.cva_inspection.step--;
        },
        next() {
            this.cva_inspection.step++;
        },
        submit() {
            // Post data.
            let inspection = this.cva_inspection
            axios({
                method: 'post',
                url: this.$root.endpoint+'/api/cvas/'+this.$root.currentCVA.id+'/inspection',
                data: {
                    inspection: inspection
                }
            }).then(response => {
                this.$root.page='pce_index'
            });
        },
        getCva(cvaId) {
            axios({
                method: 'get',
                url: this.$root.endpoint+'/api/cva/' + cvaId
            }).then(response => {
                if(response.data.pre_cva !== null) {
                    this.cva_inspection = response.data.pre_cva
                    this.cva_inspection.step = 1
                    return this.cva_inspection
                }
            });
        }
    },
    computed: {
        cvaID() {
            return this.$root.currentCVA.id;
        }
    },
    mounted(){
        this.getCva(this.cvaID)
    },
    data() {
        return {
            cva_inspection: {
                timestamp: new Date(),
                step: 1,
                steps: {
                  one: {
                      title: 'Vehicle Inspection',
                      number: 1,
                      active: true,
                      completed: false
                  },
                 two: {
                     title: 'Vehicle Hygiene',
                     number: 2,
                     active: null,
                     completed: false
                 },
                 three: {
                     title: 'Kit Bags',
                     number: 3,
                     active: null,
                     completed: false
                 }
                },
                broken_seal: {
                  0: null,
                  1: null
                },
                warn_light: {
                    0: null,
                    1: null
                },
                horn: {
                    0: null,
                    1: null
                },
                fuel_card: {
                    0: null,
                    1: null
                },
                battery: {
                    0: null,
                    1: null
                },
                hygiene: {
                    clean: null
                },
                windscreen: {
                    crack: {
                        0: null,
                        1: null
                    },
                    wipers: {
                        0: null,
                        1: null
                    },
                    wash: {
                        0: null,
                        1: null
                    }
                },
                lights: {
                    head: {
                        0: null,
                        1: null
                    },
                    break: {
                        0: null,
                        1: null
                    },
                    side: {
                        0: null,
                        1: null
                    },
                    indicators: {
                        0: null,
                        1: null
                    },
                    interior: {
                        0: null,
                        1: null
                    }
                },
                wheels: {
                    tire_pressure: {
                        0: null,
                        1: null
                    },
                    tire_thread: {
                        0: null,
                        1: null
                    },
                    hub_cap: {
                        0: null,
                        1: null
                    },
                    rims: {
                        0: null,
                        1: null
                    }
                },
                mark: {
                  0: null,
                  1: null
                },
                paint: {
                  0: null,
                  1: null
                },
                appearance: {
                    mirrors: {
                        0: null,
                        1: null
                    },
                    handles: {
                        0: null,
                        1: null
                    },
                    scratch: {
                        0: null,
                        1: null
                    }
                },
                vehicle_hygiene: {
                    left_clean: null,
                    external: null,
                    internal: null,
                    window: null,
                    rubbish: null
                },
                kitbag: {
                    number: null,
                    seal: null,
                    handcuff: null,
                    handcuff_working: null,
                    leg_straps: null,
                    metaldetector: null,
                    defib: null,
                    defib_date: null,
                    defib_pad_date: null,
                    defib_serialnumber: null
                },
                body_map: {
                    front: null,
                    left: null,
                    right: null,
                    back: null
                },
            }
        }
    },
}
</script>

<style scoped>
#appHomeBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #84d1f5;
    background-image: url("assets/img/app_top_img.jpg");
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#home_color_overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg, transparent 100px, #eeeeee 100px);
}
#home_image_overlay {
    background-image: url("assets/img/app_home_colour_overlay.png");
    width: 100px;
    height: 88px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
}

#appBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
}
#homepage_title {
    line-height: 45px;
    font-size: 50px;
    margin-left: 100px;
    margin-top: 60px;
    font-weight: bold;
    color: black;
}
body {
    min-height: 100%;
    background-color: #eeeeee !important;
}

#pcr_list {
    margin-top: 150px;
}
.pcr_list_item {
    background-color: white;
    border-radius: 20px;
    border: 1px solid #D4D4D5;
    margin: 20px;
}
.pcr_list_item_title {
    font-size: 20px;
    font-weight: bold;
    color: #3B3B3B;
}
.pcr_list_item_body {
    padding: 20px;
    color: #9D9D9D;
}
.pcr_list_item_status {
    color: rgb(204, 157, 46);
    border-top: 1px solid #DADADB;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}
.pce_card_content > * {
    margin: 20px 0px 20px 0px;
}
.pce_card_content h2 {
    font-weight: 600;
}
.pce_card_content h5 {
    font-weight: 600;
    margin: 30px 0px 30px 0px;
}
.cva_card {
    background-color: white;
    box-shadow: 0 0 4px #00000069;
    min-height: 500px;
    border-radius: 20px;
}
.progress-box-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.progress-box{
    min-width: 160px;
    border-radius: 15px;
    margin: 0px 10px 0px 10px;
}
.feedback_face_container {
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    user-select: none;
}
.feedback_face_container.active {
    background-color: #67B9CE;
}

/*Unfortunately this is duplicated in PostCVAInspection.vue update in both places*/
.pre_form_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pre_form_row p {
    margin: 0 !important;
}
/*row question*/
.three_items > *:first-child,
.vehicle_hygiene.faces > *:first-child {
    width: 160px;
}

.two_items > *:first-child,
.vehicle_hygiene.two_items > *:first-child {
    /*160 + 160 + 10*/
    width: 330px;
}
.three_items > *:first-child,
.three_items > *:nth-child(2),
.two_items > *:first-child {
    margin-right: 10px;
}

/*row toggle*/
.three_items > *:nth-child(2) {
    width: 160px;
}
/*row answer input*/
.three_items > *:last-child,
.two_items > *:last-child {
    flex: 1;
}
.vehicle_hygiene.faces > *:nth-last-child(-n+5) {
    flex: 1;
}
</style>
