<template>
    <div class="pce_card">
        <div class="pce_card_content">
            <div class="section_heading">Assignment details</div>

            <div class="row">
                <div class="col-4">
                    <label class="data_label">SC Ref Number</label>
                    <div class="data">SC-{{ value.id }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Hub</label>
                    <div class="data">{{value.hub}}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Transfer Type</label>
                    <div class="data">{{ value.type }}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Vehicle reg assigned</label>
                    <div class="data">
                        <span v-for="(vehicle, vehicle_array_index ) in $root.currentCVA.vehicles">
                            {{vehicle.name}} <span v-if="vehicle_array_index !==  $root.currentCVA.vehicles.length - 1">, </span>
                        </span>
                    </div>
                </div>
                <div class="col-4">
                    <label class="data_label">Crew assigned</label>
                    <div class="data">
                        <span v-for="(crew_member, crew_member_id, crew_members_array_index) in $root.currentCVA.crew_members">
                            {{crew_member.split(' ').map(x => x.charAt(0)).join('.').toUpperCase()}} <span v-if="crew_members_array_index !==  Object.keys($root.currentCVA.crew_members).length - 1">, </span>
                        </span>
                    </div>
                </div>
                <div class="col-4">
                    <label class="data_label">RMN assigned</label>
                    <div class="data">
                        <span v-for="(rmn_name, rmn_name_array_index) in value.rmns">
                            {{rmn_name.split(' ').map(x => x.charAt(0)).join('.').toUpperCase()}} <span v-if="rmn_name_array_index !==  value.rmns.length - 1">, </span>
                        </span>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Shadow assigned</label>
                    <div class="data">
                        <span v-for="(shadow_name, shadow_name_array_index) in value.shadows">
                            {{shadow_name.split(' ').map(x => x.charAt(0)).join('.').toUpperCase()}} <span v-if="shadow_name_array_index !==  value.shadows.length - 1">, </span>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="hl"></div>
        <div class="pce_card_content">
            <div class="section_heading">Booking Details</div>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Booker Name</label>
                    <div class="data">{{value.booker_information.booker_name}}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Booker Contact Number</label>
                    <div class="data">{{value.booker_information.booker_contact_number}} {{value.booker_information.booker_contact_number_notes}}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Approver name</label>
                    <div class="data">{{ value.booker_information.approver_name }}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Trust</label>
                    <div class="data">{{ value.booker_information.trust }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Area funding type</label>
                    <div class="data">{{ value.booker_information.area_funding }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Area funding</label>
                    <div class="data">{{ value.booker_information.area_funding_type_name }}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Budget Code for invoicing</label>
                    <div class="data">{{ value.booker_information.budget_code_for_invoicing }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Purchase order number</label>
                    <div class="data">{{ value.booker_information.purchase_order_number }}</div>
                </div>
            </div>
        </div>

        <div class="hl"></div>
        <div class="pce_card_content">
            <div class="section_heading">Job Details</div>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Scheduled date of transfer</label>
                    <div class="data">{{value.collection_timestamp.split(' ')[0]}}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Collection time</label>
                    <div class="data">{{value.collection_timestamp.split(' ')[1]}}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Vehicle requirement</label>
                    <div class="data">{{value.vehicle_requirement}}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Driver Plus</label>
                    <div class="data">D+{{value.driver_plus}}</div>
                </div>
            </div>
            <br>
            <br>
            <div v-for="journey_step in journey_steps">
                <div class="row">
                    <div class="col-4">
                        <label class="data_label">{{journey_step['description']}} Address</label>
                        <div class="data" v-if="journey_step['address']!==null&&journey_step['address']!==undefined">
                            <span v-if="journey_step['address']['ward']!==null">{{journey_step['address']['ward']}},<br></span>
                            <span v-if="journey_step['address']['hospital_name']!==null">{{journey_step['address']['hospital_name']}},<br></span>
                            <span v-if="journey_step['address']['address_line_1']!==null">{{journey_step['address']['address_line_1']}},<br></span>
                            <span v-if="journey_step['address']['address_line_2']!==null">{{journey_step['address']['address_line_2']}},<br></span>
                            <span v-if="journey_step['address']['address_line_3']!==null">{{journey_step['address']['address_line_3']}},<br></span>
                            <span v-if="journey_step['address']['address_line_4']!==null">{{journey_step['address']['address_line_4']}},<br></span>
                            <span v-if="journey_step['address']['city']!==null">{{journey_step['address']['city']}},<br></span>
                        </div>
                    </div>
                    <div class="col-4">
                        <label class="data_label">{{journey_step['description']}} Postcode</label>
                        <div class="data" v-if="journey_step['address']!==null&&journey_step['address']!==undefined">{{journey_step['address']['postcode']}}</div>
                    </div>
                    <div class="col-4">
                        <label class="data_label">{{journey_step['description']}} Contact Number</label>
                        <div class="data">{{journey_step['contact_number']}} <span v-if="journey_step['contact_number_notes']!==null">- ({{journey_step['contact_number_notes']}})</span></div>
                    </div>
                </div>
                <br>
            </div>

        </div>
        <div class="hl"></div>
        <div class="pce_card_content">
            <div class="section_heading">Patient Details</div>

            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Patient Name</label>
                    <div class="data">{{ value.patient_name }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Male / female</label>
                    <div class="data">{{ value.patient_gender }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Pronoun</label>
                    <div class="data">{{ value.patient_pronouns }}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Age on collection</label>
                    <div class="data">{{ $root.patient_age_on_collection_date }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">GP Code</label>
                    <div class="data">{{ value.patient_gp_code }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">NHS No. or X No.</label>
                    <div class="data">{{ value.patient_nhs_no }}</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Nationality</label>
                    <div class="data">{{ value.patient_nationality }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Religion / Ethnicity</label>
                    <div class="data">{{ value.patient_religion_ethnicity }}</div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-4">
                    <label class="data_label">Additional Comments</label>
                    <div class="data">{{ value.patient_additional_comments }}</div>
                </div>
                <div class="col-4">
                    <label class="data_label">Risk Assessment</label>
                    <div v-if="value.risk_assessment.risk_score_result<9" class="text-info"><b>Low risk:</b></div>
                    <div v-if="value.risk_assessment.risk_score_result>8 && value.risk_assessment.risk_score_result<13" class="text-warning"><b>Moderate risk</b></div>
                    <div v-if="value.risk_assessment.risk_score_result>12 && value.risk_assessment.risk_score_result<15" class="text-danger"><b><p>High risk</p></b></div>
                    <div v-if="value.risk_assessment.risk_score_result>14" class="data"><b><p class="text-danger">Very High risk</p></b></div>
                </div>
                <div class="col-4">
                    <label class="data_label">Risk Score</label>
                    <div class="data"> {{value.risk_assessment.risk_score_result}}</div>
                </div>
            </div>
            <div class="hl"></div>
            <div class="row">
                <div class="col-8 offset-2">
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result<9}">
                        <div class="col-4">
                            6-8 Low Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 1 Escort
                        </div>
                    </div>
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>8&&value.risk_assessment.risk_score_result<13}">
                        <div class="col-4">
                            9-12 Medium Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 2 Escorts and Cell vehicle or Driver Plus 2
                        </div>
                    </div>
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>12&&value.risk_assessment.risk_score_result<15}">
                        <div class="col-4">
                            13-14 High Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 2/3 and Cell Vehicle or Driver Plus 3
                        </div>
                    </div>
                    <div class="row border-dark border p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>14}">
                        <div class="col-4">
                            15 or over Very High Risk
                        </div>
                        <div class="col-8">
                            Driver plus 3 and Cell Vehicle
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>

        <div v-if="value.cancelled">
            <div class="hl"></div>
            <div class="pce_card_content">
                <div class="section_heading">Cancellation Details</div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <label class="data_label">Cancellation type</label>
                        <div class="data" v-if="value.cancellation_type===1">Pre day</div>
                        <div class="data" v-else-if="value.cancellation_type===2">Same day</div>
                        <div class="data" v-else-if="value.cancellation_type===3">Deployed</div>
                    </div>
                    <div class="col-4">
                        <label class="data_label">Cancellation reason</label>
                        <div class="data">{{ value.cancellation_reason }}</div>
                    </div>
                    <div class="col-4">
                        <label class="data_label">Cancelled by whom</label>
                        <div class="data">{{ value.cancelled_by }}</div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <label class="data_label">Cancellation notes (comments)</label>
                        <div class="data">{{value.cancellation_notes}}</div>
                    </div>
                </div>

            </div>
        </div>


        <br><br>
        <center>
            <div v-if="value.cancelled===1" class="btn btn-primary" @click="value.crew_acknowledged_cancellation_at=$root.date_to_time_string(new Date()); $root.save_and_close_pce()">Accept Cancellation</div>
            <div v-if="value.crew_accepted_transfer_at===null&&value.cancelled!==1" class="btn btn-primary" @click="value.crew_accepted_transfer_at=$root.date_to_time_string(new Date()); $root.queue_save_current_pce()">Accept Patient Care Episode</div>
            <div v-else-if="value.started_at===null" @click="startPceJourney()" class="btn btn-primary">Start Patient Care Episode</div>
        </center>
        <br><br>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    mounted: function() {
      //console.log(this.value)
    },
    computed: {
        journey_steps: function () {
            var to_return = [];
            this.value.journey_steps.forEach((journey_step) => {
                var description = journey_step['description'];
                if (journey_step['type']==="collection") {
                    description = 'Collection'
                } else if (journey_step['type']==="destination") {
                    description = 'Destination'
                }
                to_return.push({
                    'description': description,
                    'address': journey_step['address'],
                    'contact_number': journey_step['contact_number'],
                    'contact_number_notes': journey_step['contact_number_notes'],
                })
            })
            return to_return;
        },
    },
    methods: {
        startPceJourney: function () {
            this.value.started_at = this.$root.date_to_time_string(new Date());
            this.$root.queue_save_current_pce()
        }
    },
}
</script>

<style scoped>

</style>
