<template>
    <div class="option_toggle_container" tabindex="0" v-on:keyup="keyMonitor">
        <div @click="setValue(option_index)" v-for="(option_item, option_index) in toggle_options" class="option_toggle_item" :class="{'option_toggle_active':(option_index === value), 'option_toggle_item_left':(option_index === 0), 'option_toggle_item_right':(option_index === toggle_options.length-1)}">{{option_item}}</div>
    </div>
</template>

<script>
export default {
    props: {
        'value': {
            validator: prop => typeof prop === 'number' || prop === null,
            required: true,
        },
        'toggle_options': {
            type: Array,
            required: true,
        },
        'editable': {
            type: Boolean,
            default: true,
        }
    },
    mounted() {
    },
    data() {
        return {
            selected_hour: null,
            selected_mins: null,
            formatted_hour: null,
            picker_type: 'hour',
            active: false,
        }
    },
    methods: {
        keyMonitor: function (event) {
            // Ok keypress, let's:
            // If the key is numeric, check to see if it's a valid option. If so, select it!
            // Otherwise let's see if the key matches the first character of any option's label, if so let's select the last option it matches...
            if (isNaN(event.key)) {
                var lowercase_key = event.key.toLowerCase();
                var value_to_select = null;
                this.toggle_options.forEach((option_label, option_index)=>{
                    if (option_label.substring(0, 1).toLowerCase()===lowercase_key) {
                        value_to_select = option_index;
                    }
                });
                if (value_to_select!==null) {
                    this.setValue(value_to_select)
                }

            } else {
                // Let's see if (event.key-1) is a valid value...
                if (this.toggle_options[event.key-1] !== undefined) {
                    this.setValue(event.key-1)
                }
            }
        },
        setValue: function (value) {
            if (this.editable) {
                this.$emit('input',  value);
                this.$emit('change');
            }
        },
    }

}
</script>

<style scoped>
.option_toggle_container {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    border-radius: 10px;
}
.option_toggle_container:focus {
    border-color: #a1cbef !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(52 144 220 / 25%) !important;
}
.option_toggle_item_left {
    border-left: 0px;
    border-radius: 10px 0 0 10px;
}
.option_toggle_item_right {
    border-left: 1px solid #c7c7c7;
    border-radius: 0 10px 10px 0;
}
.option_toggle_item {
    font-weight: bold;
    font-size: 12.5px;
    line-height: 20px;
    display: inline-block;
    color: #676767;
    background-color: #CECECE;
    padding: 5px;
    width: 80px;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #c7c7c7;
}
.option_toggle_item.option_toggle_active {
    background-color: #67C9E0;
    color: white;
}
</style>
