<template>


    <div>
        <app-pce-hero-banner id="pce_hero" :title="$parent.patientInitials+' '+$parent.value.type" v-on:close="$root.save_and_close_pce()"></app-pce-hero-banner>
        <div id="pce_nav">
            <div :class="{'active':value==='pce_assignment'}" class="pce_nav_item" @click="$emit('input', 'pce_assignment')">
                <svg class="pce_nav_item_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_1683" data-name="Rectangle 1683" width="25" height="25" transform="translate(71 6)" class="icon_fill"/>
                        </clipPath>
                    </defs>
                    <g id="Mask_Group_17" data-name="Mask Group 17" transform="translate(-71 -6)" clip-path="url(#clip-path)">
                        <g id="assignment-24px" transform="translate(71 6)">
                            <path id="Path_812" data-name="Path 812" d="M0,0H25V25H0Z" fill="none"/>
                            <path id="Path_813" data-name="Path 813" d="M19.792,3.125H15.437a3.113,3.113,0,0,0-5.875,0H5.208A2.089,2.089,0,0,0,3.125,5.208V19.792a2.089,2.089,0,0,0,2.083,2.083H19.792a2.089,2.089,0,0,0,2.083-2.083V5.208A2.089,2.089,0,0,0,19.792,3.125Zm-7.292,0a1.042,1.042,0,1,1-1.042,1.042A1.045,1.045,0,0,1,12.5,3.125Zm2.083,14.583H7.292V15.625h7.292Zm3.125-4.167H7.292V11.458H17.708Zm0-4.167H7.292V7.292H17.708Z" class="icon_fill"/>
                        </g>
                    </g>
                </svg>
                <div class="pce_nav_item_text">PCE assignment</div>
            </div>

            <div :class="{'active':value==='patient_into_care'}" class="pce_nav_item" @click="$emit('input', 'patient_into_care')">
                <svg class="pce_nav_item_icon" xmlns="http://www.w3.org/2000/svg" width="22.917" height="16.667" viewBox="0 0 22.917 16.667">
                    <g id="Group_1288" data-name="Group 1288" transform="translate(-1.042 -4.167)">
                        <g id="Group_1287" data-name="Group 1287">
                            <g id="Group_1283" data-name="Group 1283">
                                <path id="Path_809" data-name="Path 809" d="M17.365,13.677a4.845,4.845,0,0,1,2.427,4.031v3.125h4.167V17.708C23.958,15.437,20.24,14.094,17.365,13.677Z" class="icon_fill" fill-rule="evenodd"/>
                            </g>
                            <g id="Group_1284" data-name="Group 1284">
                                <circle id="Ellipse_24" data-name="Ellipse 24" cx="4.167" cy="4.167" r="4.167" transform="translate(5.208 4.167)" class="icon_fill"/>
                            </g>
                            <g id="Group_1285" data-name="Group 1285">
                                <path id="Path_810" data-name="Path 810" d="M15.625,12.5a4.167,4.167,0,1,0,0-8.333,4.352,4.352,0,0,0-1.385.25,6.229,6.229,0,0,1,0,7.833A4.352,4.352,0,0,0,15.625,12.5Z"class="icon_fill" fill-rule="evenodd"/>
                            </g>
                            <g id="Group_1286" data-name="Group 1286">
                                <path id="Path_811" data-name="Path 811" d="M9.375,13.542c-2.781,0-8.333,1.4-8.333,4.167v3.125H17.708V17.708C17.708,14.938,12.156,13.542,9.375,13.542Z" class="icon_fill" fill-rule="evenodd"/>
                            </g>
                        </g>
                    </g>
                </svg>
                <div class="pce_nav_item_text">patient into care</div>
            </div>
            <div :class="{'active':value==='rsri'}" class="pce_nav_item" @click="$emit('input', 'rsri')" style="width: 180px;">
                <svg class="pce_nav_item_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_1683" data-name="Rectangle 1683" width="25" height="25" transform="translate(62)" class="icon_fill"/>
                        </clipPath>
                    </defs>
                    <g id="Mask_Group_20" data-name="Mask Group 20" transform="translate(-62)" clip-path="url(#clip-path)">
                        <g id="pan_tool-black-18dp" transform="translate(62)">
                            <g id="Group_1544" data-name="Group 1544">
                                <rect id="Rectangle_1714" data-name="Rectangle 1714" width="25" height="25" fill="none"/>
                            </g>
                            <g id="Group_1547" data-name="Group 1547">
                                <g id="Group_1546" data-name="Group 1546">
                                    <g id="Group_1545" data-name="Group 1545">
                                        <path id="Path_832" data-name="Path 832" d="M23.958,5.729v15.1A4.179,4.179,0,0,1,19.792,25h-7.6a4.16,4.16,0,0,1-2.969-1.24L1.042,15.448s1.312-1.281,1.354-1.3a1.234,1.234,0,0,1,.823-.3,1.273,1.273,0,0,1,.625.167c.042.01,4.49,2.562,4.49,2.562V4.167a1.562,1.562,0,1,1,3.125,0v7.292H12.5v-9.9a1.562,1.562,0,1,1,3.125,0v9.9h1.042V2.6a1.563,1.563,0,0,1,3.125,0v8.854h1.042V5.729a1.563,1.563,0,0,1,3.125,0Z" class="icon_fill"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <div class="pce_nav_item_text">Restraints, searches, and  reportable incidents</div>
            </div>

            <div :class="{'active':value==='patient_handover'}" class="pce_nav_item" @click="$emit('input', 'patient_handover')">
                <svg class="pce_nav_item_icon" xmlns="http://www.w3.org/2000/svg" width="18.917" height="18.729" viewBox="0 0 18.917 18.729">
                    <path id="Path_815" data-name="Path 815" d="M4.781,7.177c.729-.74,1.458-1.406,1.781-1.271.521.208,0,1.073-.312,1.583-.26.438-2.979,4.052-2.979,6.573a3.737,3.737,0,0,0,1.4,3.1,3.121,3.121,0,0,0,2.75.479c1.115-.323,2.031-1.458,3.188-2.885,1.26-1.552,2.948-3.583,4.25-3.583,1.7,0,1.719,1.052,1.833,1.865a6.366,6.366,0,0,0-5.6,5.594,3.289,3.289,0,0,0,3.344,3.219c1.7,0,4.469-1.385,4.885-6.354h2.563V12.9H19.3c-.156-1.719-1.135-4.375-4.2-4.375-2.344,0-4.354,1.99-5.146,2.958-.6.76-2.146,2.583-2.385,2.833-.26.313-.708.875-1.156.875-.469,0-.75-.865-.375-2A19.008,19.008,0,0,1,7.969,9.521,5.517,5.517,0,0,0,9.323,6.1,2.745,2.745,0,0,0,6.708,3.125a4.543,4.543,0,0,0-2.833,1.3c-.375.375-.688.688-.917.969Zm9.677,12.146a.782.782,0,0,1-.771-.75,3.687,3.687,0,0,1,2.99-2.875C16.365,18.5,15.187,19.323,14.458,19.323Z" transform="translate(-2.958 -3.125)" class="icon_fill"/>
                </svg>



                <div class="pce_nav_item_text">patient handover</div>
            </div>

            <div :class="{'active':value==='ra'}" class="pce_nav_item" id="ra_icon" @click="$emit('input', 'ra')">
                <svg class="pce_nav_item_icon" xmlns="http://www.w3.org/2000/svg" width="20.833" height="18.75" viewBox="0 0 20.833 18.75">
                    <g id="Group_1730" data-name="Group 1730" transform="translate(-2.083 -3.125)">
                        <g id="Group_1729" data-name="Group 1729">
                            <path id="Path_846" data-name="Path 846" d="M20.833,3.125H4.167A2.089,2.089,0,0,0,2.083,5.208V19.792a2.089,2.089,0,0,0,2.083,2.083H20.833a2.089,2.089,0,0,0,2.083-2.083V5.208A2.089,2.089,0,0,0,20.833,3.125Zm0,16.667H4.167V5.208H20.833Z"  class="icon_fill" fill-rule="evenodd"/>
                            <path id="Path_847" data-name="Path 847" d="M20.219,10.854,18.74,9.375l-3.3,3.3L13.969,11.2,12.5,12.667l2.937,2.958Z" class="icon_fill" fill-rule="evenodd"/>
                            <rect id="Rectangle_1739" data-name="Rectangle 1739" width="5.208" height="2.083" transform="translate(5.208 7.292)" class="icon_fill"/>
                            <rect id="Rectangle_1740" data-name="Rectangle 1740" width="5.208" height="2.083" transform="translate(5.208 11.458)" class="icon_fill"/>
                            <rect id="Rectangle_1741" data-name="Rectangle 1741" width="5.208" height="2.083" transform="translate(5.208 15.625)" class="icon_fill"/>
                        </g>
                    </g>
                </svg>



                <div class="pce_nav_item_text">patient risk assessment</div>
            </div>

        </div>
        <app-pce-journey-status-bar v-model="$parent.value"></app-pce-journey-status-bar>
        <div id="nav_bar_spacer"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
    },
}
</script>

<style scoped>
#pce_hero {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
#pce_nav {
    background-color: white;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 0 3px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pce_nav_item {
    padding: 0 10px;
    box-sizing: content-box;
    width: 100px;
    user-select: none;
    cursor: pointer;
}
.pce_nav_item_icon {
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
}
.icon_fill {
    fill: #676767;
}
.pce_nav_item.active .icon_fill {
    fill: #67C9E0;
}
.pce_nav_item_text {
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    line-height: 14px;
    margin-top: 5px;
}
.pce_nav_item.active .pce_nav_item_text {
    color: #67C9E0;
}
#nav_bar_spacer {
    height: 260px;
}
#ra_icon {
    position: fixed;
    right: 0px;
    border-left: 1px solid #bbb;
}
</style>
