<template>
    <div>
        <v-select :placeholder="placeholder" v-model="vue_select_model" @input="update_value()" :options="options"
                  multiple :selectable="option => ! value.includes(option.value)"></v-select>
        <input :name="name" :value="value" type="hidden">
    </div>
</template>

<script>
export default {
    mounted() {
        this.update_model();
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            required: true,
            default: []
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option'
        },
    },
    data() {
        return {
            vue_select_model: [],
        }
    },
    beforeUpdate: function () {
        this.update_model();
    },
    methods: {
        update_model: function () { // Updates this.vue_select_model to mirror this.value
            this.vue_select_model = this.options.filter(item => {
                if (this.value.includes(item['value'])) return item;
            })
        },
        update_value: function () { // Updates this.value to mirror this.vue_select_model
            this.$emit('input', this.vue_select_model.map(item => item.value));
        }
    },
}
</script>
